import * as tslib_1 from "tslib";
import { Select, Store } from '@ngxs/store';
import { AddToWatchlist, RemoveFromWatchlist } from '../../../lists/user-lists/state/user-lists-state-actions';
import { UserListsState } from '../../../lists/user-lists/state/user-lists-state';
import { Observable } from 'rxjs';
export class TitleActionButtonsComponent {
    constructor(store) {
        this.store = store;
    }
    isInWatchlist() {
        const watchlist = this.store.selectSnapshot(UserListsState.watchlist);
        if (!watchlist)
            return false;
        return watchlist.items.findIndex(i => i.id === this.item.id && i.type === this.item.type) > -1;
    }
    addToWatchlist() {
        this.store.dispatch(new AddToWatchlist(this.item));
    }
    removeFromWatchlist() {
        this.store.dispatch(new RemoveFromWatchlist(this.item));
    }
}
tslib_1.__decorate([
    Select(UserListsState.loading),
    tslib_1.__metadata("design:type", Observable)
], TitleActionButtonsComponent.prototype, "loading$", void 0);
