/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rating-widget.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../../common/core/ui/auth-click.directive";
import * as i9 from "../../../../../common/auth/current-user";
import * as i10 from "@angular/router";
import * as i11 from "../../../../../common/core/translations/translate.directive";
import * as i12 from "../../../../../common/core/translations/translations.service";
import * as i13 from "../../../../../common/core/config/settings.service";
import * as i14 from "@angular/common";
import * as i15 from "./rating-widget.component";
import * as i16 from "../../../../../common/core/ui/overlay-panel/overlay-panel.service";
import * as i17 from "@ngxs/store";
var styles_RatingWidgetComponent = [i0.styles];
var RenderType_RatingWidgetComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_RatingWidgetComponent, data: {} });
export { RenderType_RatingWidgetComponent as RenderType_RatingWidgetComponent };
function View_RatingWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "current-rating"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "star"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " / 10"]))], function (_ck, _v) { var currVal_2 = "star"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.item.rating; _ck(_v, 4, 0, currVal_3); }); }
function View_RatingWidgetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "separator"]], null, null, null, null, null))], null, null); }
function View_RatingWidgetComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "button", [["class", "user-rating rate-button"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "authClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).click($event) !== false);
        ad = (pd_0 && ad);
    } if (("authClick" === en)) {
        var pd_1 = (_co.openRatingOverlay($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 16384, null, 0, i8.AuthClickDirective, [i9.CurrentUser, i10.Router], null, { authClick: "authClick" }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "star"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 6, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(7, 4341760, null, 0, i11.TranslateDirective, [i1.ElementRef, i12.Translations, i13.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Your Score"])), (_l()(), i1.ɵted(-1, null, [": "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "score"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", ""]))], function (_ck, _v) { var currVal_4 = "star"; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_5 = _v.context.ngIf.score; _ck(_v, 11, 0, currVal_5); }); }
function View_RatingWidgetComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["class", "rate-this rate-button"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "authClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).click($event) !== false);
        ad = (pd_0 && ad);
    } if (("authClick" === en)) {
        var pd_1 = (_co.openRatingOverlay($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 16384, null, 0, i8.AuthClickDirective, [i9.CurrentUser, i10.Router], null, { authClick: "authClick" }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "star-border"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "span", [["class", "text"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(6, 4341760, null, 0, i11.TranslateDirective, [i1.ElementRef, i12.Translations, i13.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Rate This"]))], function (_ck, _v) { var currVal_4 = "star-border"; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_RatingWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RatingWidgetComponent_3)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_RatingWidgetComponent_4)), i1.ɵdid(4, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i14.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["rateThis", 2]], null, 0, null, View_RatingWidgetComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.rating; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.userRating$)); var currVal_2 = i1.ɵnov(_v, 6); _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_RatingWidgetComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RatingWidgetComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RatingWidgetComponent_2)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.rating; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showRateButton; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RatingWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rating-widget", [], null, null, null, View_RatingWidgetComponent_0, RenderType_RatingWidgetComponent)), i1.ɵdid(1, 573440, null, 0, i15.RatingWidgetComponent, [i16.OverlayPanel, i17.Store], null, null)], null, null); }
var RatingWidgetComponentNgFactory = i1.ɵccf("rating-widget", i15.RatingWidgetComponent, View_RatingWidgetComponent_Host_0, { item: "item", showRateButton: "showRateButton" }, {}, []);
export { RatingWidgetComponentNgFactory as RatingWidgetComponentNgFactory };
