import * as tslib_1 from "tslib";
var SettingsState_1;
import { Action, createSelector, State, Store } from '@ngxs/store';
import * as Dot from 'dot-object';
import { Settings } from '@common/core/config/settings.service';
export class SetSettings {
    constructor(settings) {
        this.settings = settings;
    }
}
SetSettings.type = '[Settings] Set';
let SettingsState = SettingsState_1 = class SettingsState {
    constructor(settings, store) {
        this.settings = settings;
        this.store = store;
    }
    static setting(name) {
        return createSelector([SettingsState_1], (state) => {
            return Dot.pick(name, state);
        });
    }
    ngxsOnInit(ctx) {
        this.settings.all$().subscribe(settings => {
            this.store.dispatch(new SetSettings(settings));
        });
    }
    setSettings(ctx, action) {
        ctx.patchState(action.settings);
    }
};
tslib_1.__decorate([
    Action(SetSettings),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetSettings]),
    tslib_1.__metadata("design:returntype", void 0)
], SettingsState.prototype, "setSettings", null);
SettingsState = SettingsState_1 = tslib_1.__decorate([
    State({
        name: 'settings',
        defaults: {},
    }),
    tslib_1.__metadata("design:paramtypes", [Settings, Store])
], SettingsState);
export { SettingsState };
