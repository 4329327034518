import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { AttachItem, CreateOrUpdateList, DeleteList, DetachItem, ReloadList, ReorderList, ResetState, UpdateDetails } from '../state/list-actions';
import { ListState } from '../state/list-state';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Modal } from '../../../../common/core/ui/dialogs/modal.service';
import { ConfirmModalComponent } from '../../../../common/core/ui/confirm-modal/confirm-modal.component';
import { Toast } from '../../../../common/core/ui/toast.service';
import { MESSAGES } from '../../../toast-messages';
import { LIST_AUTO_UPDATE_OPTIONS } from '../list-auto-update-options';
import { CurrentUser } from '../../../../common/auth/current-user';
export class CrupdateListComponent {
    constructor(store, route, router, modal, toast, currentUser) {
        this.store = store;
        this.route = route;
        this.router = router;
        this.modal = modal;
        this.toast = toast;
        this.currentUser = currentUser;
        this.autoUpdateOptions = LIST_AUTO_UPDATE_OPTIONS;
        this.form = new FormGroup({
            name: new FormControl(''),
            public: new FormControl(false),
            description: new FormControl(''),
            auto_update: new FormControl(null),
        });
    }
    ngOnInit() {
        this.route.params.subscribe(params => {
            this.hydrateList(params.id);
        });
    }
    ngOnDestroy() {
        this.store.dispatch(new ResetState());
    }
    attachItem(item) {
        this.store.dispatch(new AttachItem(item));
    }
    detachItem(item) {
        this.store.dispatch(new DetachItem(item));
    }
    createOrUpdateList() {
        this.store.dispatch([
            new UpdateDetails(this.form.getRawValue()),
            new CreateOrUpdateList(),
        ]);
    }
    reorderList(e) {
        if (this.store.selectSnapshot(ListState.loading))
            return;
        this.store.dispatch(new ReorderList(e.previousIndex, e.currentIndex));
    }
    hydrateList(id) {
        if (!id)
            return;
        this.store.dispatch(new ReloadList(id)).subscribe(() => {
            const list = this.store.selectSnapshot(ListState.list);
            this.form.patchValue(list);
            if (list.system) {
                this.form.get('name').disable();
                this.form.get('public').disable();
            }
        });
    }
    maybeDeleteList() {
        this.modal.open(ConfirmModalComponent, {
            title: 'Delete List',
            body: 'Are you sure you want to delete this list?',
            ok: 'Delete'
        }).afterClosed().subscribe(confirmed => {
            if (!confirmed)
                return;
            const id = this.store.selectSnapshot(ListState.list).id;
            return this.store.dispatch(new DeleteList(id)).subscribe(() => {
                this.router.navigate(['/lists']);
                this.toast.open(MESSAGES.LIST_DELETE_SUCCESS);
            });
        });
    }
}
tslib_1.__decorate([
    Select(ListState.items),
    tslib_1.__metadata("design:type", Observable)
], CrupdateListComponent.prototype, "items$", void 0);
tslib_1.__decorate([
    Select(ListState.updating),
    tslib_1.__metadata("design:type", Observable)
], CrupdateListComponent.prototype, "updating$", void 0);
tslib_1.__decorate([
    Select(ListState.loading),
    tslib_1.__metadata("design:type", Observable)
], CrupdateListComponent.prototype, "loading$", void 0);
tslib_1.__decorate([
    Select(ListState.system),
    tslib_1.__metadata("design:type", Observable)
], CrupdateListComponent.prototype, "system$", void 0);
