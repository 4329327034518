import * as tslib_1 from "tslib";
import { OnChanges, SimpleChange } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { PlayVideo } from '../../../player/state/player-state-actions';
import { MEDIA_TYPE } from '../../../media-type';
export class TitleSecondaryDetailsPanelComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnChanges(changes) {
        if (changes.item.currentValue && changes.item.currentValue.credits) {
            this.setCrew();
        }
    }
    playVideo(video) {
        const title = this.store.selectSnapshot(TitleState.title);
        this.store.dispatch(new PlayVideo(video, title));
    }
    isSeries() {
        return this.item.type === MEDIA_TYPE.TITLE && this.item.is_series;
    }
    setCrew() {
        const credits = this.store.selectSnapshot(TitleState.titleOrEpisodeCredits);
        this.credits = {
            director: this.getDirector(credits),
            writers: this.getWriters(credits),
            cast: this.getCast(credits),
            creators: this.getCreators(credits),
        };
    }
    getDirector(credits) {
        return credits.find(person => person.pivot.department === 'directing');
    }
    getWriters(credits) {
        return credits.filter(person => person.pivot.department === 'writing');
    }
    getCast(credits) {
        return credits.filter(person => person.pivot.department === 'cast').slice(0, 3);
    }
    getCreators(credits) {
        return credits.filter(person => person.pivot.department === 'creators');
    }
}
tslib_1.__decorate([
    Select(TitleState.title),
    tslib_1.__metadata("design:type", Observable)
], TitleSecondaryDetailsPanelComponent.prototype, "title$", void 0);
tslib_1.__decorate([
    Select(TitleState.videoCoverImage),
    tslib_1.__metadata("design:type", Observable)
], TitleSecondaryDetailsPanelComponent.prototype, "videoCoverImage$", void 0);
tslib_1.__decorate([
    Select(TitleState.primaryVideo),
    tslib_1.__metadata("design:type", Observable)
], TitleSecondaryDetailsPanelComponent.prototype, "primaryVideo$", void 0);
tslib_1.__decorate([
    Select(TitleState.seasons),
    tslib_1.__metadata("design:type", Observable)
], TitleSecondaryDetailsPanelComponent.prototype, "seasons$", void 0);
