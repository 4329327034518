import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { ToggleGlobalLoader } from '../../../../state/app-state-actions';
import { Settings } from '../../../../../common/core/config/settings.service';
export class EpisodePageComponent {
    constructor(store, settings) {
        this.store = store;
        this.settings = settings;
    }
    ngOnInit() {
        // TODO: remove settimout
        setTimeout(() => {
            this.store.dispatch(new ToggleGlobalLoader(false));
        });
    }
}
tslib_1.__decorate([
    Select(TitleState.episode),
    tslib_1.__metadata("design:type", Observable)
], EpisodePageComponent.prototype, "episode$", void 0);
tslib_1.__decorate([
    Select(TitleState.backdrop),
    tslib_1.__metadata("design:type", Observable)
], EpisodePageComponent.prototype, "backdropImage$", void 0);
