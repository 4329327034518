import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class PeopleService {
    constructor(http) {
        this.http = http;
    }
    getAll(params) {
        return this.http.get('people', params);
    }
    get(id) {
        return this.http.get('people/' + id);
    }
    create(payload) {
        return this.http.post('people', payload);
    }
    update(id, payload) {
        return this.http.put('people/' + id, payload);
    }
    delete(ids) {
        return this.http.delete('people', { ids });
    }
}
PeopleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PeopleService_Factory() { return new PeopleService(i0.ɵɵinject(i1.AppHttpClient)); }, token: PeopleService, providedIn: "root" });
