import * as tslib_1 from "tslib";
var TitleState_1;
import { Action, Selector, State, Store } from '@ngxs/store';
import { TITLE_STATE_MODEL_DEFAULTS } from './title-state-model-defaults';
import { Router } from '@angular/router';
import { AddVideo, CrupdateReview, DeleteReview, DeleteVideo, LoadRelatedTitles, LoadReviews, LoadTitle, RateVideo, ReportVideo, SetTitle, UpdateVideo } from './title-actions';
import { TitlesService } from '../titles.service';
import { tap } from 'rxjs/operators';
import { ReviewService } from '../../shared/review.service';
import { objectsAreEqual } from '@common/core/utils/objects-are-equal';
import { VideoService } from '../../videos/video.service';
import { Toast } from '@common/core/ui/toast.service';
import { SettingsState } from '../../settings-state';
let TitleState = TitleState_1 = class TitleState {
    constructor(router, store, titles, reviews, videos, toast) {
        this.router = router;
        this.store = store;
        this.titles = titles;
        this.reviews = reviews;
        this.videos = videos;
        this.toast = toast;
    }
    static backdrop(state) {
        return state.title.backdrop;
    }
    static videoCoverImage(state) {
        const episodeImage = state.episode && state.episode.poster, titleImage = state.title.images[0];
        let image = episodeImage || titleImage || state.title.backdrop;
        if (typeof image !== 'string') {
            image = image.url;
        }
        return image || null;
    }
    static primaryVideo(state, preferFull) {
        if (preferFull) {
            return state.title.videos.find(video => video.category === 'full' && video.type !== 'external');
        }
        else {
            return state.title.videos.find(video => video.category !== 'full' && video.type !== 'external');
        }
    }
    static videos(state, category) {
        switch (category) {
            case 'full':
                return state.videos.filter(v => v.category === 'full');
            case 'short':
                return state.videos.filter(v => v.category !== 'full');
            case 'trailer':
                return state.videos.filter(v => v.category === 'trailer');
            case 'clip':
                return state.videos.filter(v => v.category === 'clip');
            default:
                return state.videos;
        }
    }
    static loading(state) {
        return state.loading;
    }
    static episodes(state) {
        return state.episodes || state.title.season.episodes;
    }
    static episode(state) {
        return state.episode;
    }
    static title(state) {
        return state.title;
    }
    static seasons(state) {
        return state.title.seasons;
    }
    static season(state) {
        return state.season;
    }
    static reviews(state) {
        return state.title.reviews;
    }
    static relatedTitles(state) {
        return state.related;
    }
    static titleOrEpisodeCredits(state) {
        if (state.episode) {
            return state.season.credits.concat(state.episode.credits);
        }
        else {
            return state.title.credits;
        }
    }
    static titleOrEpisodeCast(state, credits) {
        return credits.filter(person => person.pivot.department === 'cast');
    }
    static titleOrEpisode(state) {
        return state.episode || state.title;
    }
    static currentEpisode(state) {
        return state.current_episode;
    }
    static nextEpisode(state) {
        return state.next_episode;
    }
    static metaTags(state) {
        return state.metaTags;
    }
    loadTitle(ctx, action) {
        const state = ctx.getState();
        // already have this title loaded and no query params changed, can bail
        if (objectsAreEqual(action.params, state.titleQueryParams, false))
            return;
        return this.titles.get(action.titleId, action.params).pipe(tap(response => {
            ctx.dispatch(new SetTitle(response, action.params));
        }));
    }
    setTitle(ctx, action) {
        const response = action.response;
        const newState = {
            title: action.response.title,
            titleQueryParams: action.params,
            episode: null,
            season: null,
            current_episode: null,
            next_episode: null,
            metaTags: response.seo,
            videos: [],
        };
        if (action.params.episodeNumber) {
            newState.episode = response.title.season.episodes.find(ep => {
                return ep.episode_number === +action.params.episodeNumber;
            });
        }
        if (action.params.seasonNumber) {
            newState.season = response.title.season;
        }
        if (response.current_episode && response.next_episode) {
            newState.current_episode = response.current_episode;
            newState.next_episode = response.next_episode;
        }
        newState.videos = newState.title.videos;
        ctx.patchState(newState);
    }
    loadRelatedTitles(ctx) {
        return this.titles.getRelatedTitles(ctx.getState().title, { limit: 5 }).pipe(tap(response => {
            ctx.patchState({ related: response.titles });
        }));
    }
    loadReviews(ctx) {
        // reviews are already loaded
        if (ctx.getState().title.reviews)
            return;
        const params = {
            titleId: ctx.getState().title.id,
            limit: 35,
            withTextOnly: true,
            with: 'user',
        };
        return this.reviews.getAll(params).pipe(tap(response => {
            ctx.patchState({
                title: Object.assign({}, ctx.getState().title, { reviews: response.pagination.data })
            });
        }));
    }
    crupdateReview(ctx, action) {
        const oldReviews = ctx.getState().title.reviews.slice();
        const index = oldReviews.findIndex(r => r.id === action.review.id);
        if (index > -1) {
            oldReviews[index] = action.review;
        }
        else {
            oldReviews.push(action.review);
        }
        ctx.patchState({
            title: Object.assign({}, ctx.getState().title, { reviews: oldReviews })
        });
    }
    deleteReview(ctx, action) {
        return this.reviews.delete([action.review.id]).pipe(tap(() => {
            const newReviews = ctx.getState().title.reviews.filter(curr => curr.id !== action.review.id);
            ctx.patchState({
                title: Object.assign({}, ctx.getState().title, { reviews: newReviews })
            });
        }));
    }
    addVideo(ctx, action) {
        ctx.patchState({ videos: [...ctx.getState().videos, action.video] });
    }
    updateVideo(ctx, action) {
        const videos = ctx.getState().videos.map(video => {
            if (video.id === action.video.id) {
                return action.video;
            }
            else {
                return video;
            }
        });
        ctx.patchState({ videos: videos });
    }
    rateVideo(ctx, action) {
        ctx.patchState({ loading: true });
        return this.videos.rate(action.video.id, action.rating)
            .pipe(tap(response => {
            const videos = ctx.getState().videos.map(v => {
                v = Object.assign({}, v);
                if (v.id === action.video.id) {
                    v.positive_votes = response.video.positive_votes;
                    v.negative_votes = response.video.negative_votes;
                }
                return v;
            });
            ctx.patchState({ videos: videos, loading: false });
        }, () => ctx.patchState({ loading: false })));
    }
    deleteVideo(ctx, action) {
        ctx.patchState({ loading: true });
        return this.videos.delete([action.video.id])
            .pipe(tap(() => {
            const videos = ctx.getState().videos.filter(v => v.id !== action.video.id);
            ctx.patchState({ videos: videos, loading: false });
            this.toast.open('Video deleted.');
        }, () => ctx.patchState({ loading: false })));
    }
    reportVideo(ctx, action) {
        ctx.patchState({ loading: true });
        return this.videos.report(action.video.id)
            .pipe(tap(() => {
            ctx.patchState({ loading: false });
            this.toast.open('Video reported.');
        }, () => {
            ctx.patchState({ loading: false });
            this.toast.open('You have already reported this video.');
        }));
    }
};
tslib_1.__decorate([
    Action(LoadTitle),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, LoadTitle]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState.prototype, "loadTitle", null);
tslib_1.__decorate([
    Action(SetTitle),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetTitle]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState.prototype, "setTitle", null);
tslib_1.__decorate([
    Action(LoadRelatedTitles),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState.prototype, "loadRelatedTitles", null);
tslib_1.__decorate([
    Action(LoadReviews),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState.prototype, "loadReviews", null);
tslib_1.__decorate([
    Action(CrupdateReview),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CrupdateReview]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState.prototype, "crupdateReview", null);
tslib_1.__decorate([
    Action(DeleteReview),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DeleteReview]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState.prototype, "deleteReview", null);
tslib_1.__decorate([
    Action(AddVideo),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddVideo]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState.prototype, "addVideo", null);
tslib_1.__decorate([
    Action(UpdateVideo),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateVideo]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState.prototype, "updateVideo", null);
tslib_1.__decorate([
    Action(RateVideo),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, RateVideo]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState.prototype, "rateVideo", null);
tslib_1.__decorate([
    Action(DeleteVideo),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DeleteVideo]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState.prototype, "deleteVideo", null);
tslib_1.__decorate([
    Action(ReportVideo),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ReportVideo]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState.prototype, "reportVideo", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "backdrop", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "videoCoverImage", null);
tslib_1.__decorate([
    Selector([SettingsState.setting('streaming.prefer_full')]),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, Boolean]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "primaryVideo", null);
tslib_1.__decorate([
    Selector([SettingsState.setting('streaming.video_panel_content')]),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, String]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "videos", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "loading", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "episodes", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "episode", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "title", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "seasons", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "season", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "reviews", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "relatedTitles", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "titleOrEpisodeCredits", null);
tslib_1.__decorate([
    Selector([TitleState_1.titleOrEpisodeCredits]),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "titleOrEpisodeCast", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "titleOrEpisode", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "currentEpisode", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "nextEpisode", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], TitleState, "metaTags", null);
TitleState = TitleState_1 = tslib_1.__decorate([
    State({
        name: 'title',
        defaults: TITLE_STATE_MODEL_DEFAULTS,
    }),
    tslib_1.__metadata("design:paramtypes", [Router,
        Store,
        TitlesService,
        ReviewService,
        VideoService,
        Toast])
], TitleState);
export { TitleState };
