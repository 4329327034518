/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./people-list-widget.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./people-list-widget.component";
import * as i5 from "../../title-urls.service";
var styles_PeopleListWidgetComponent = [i0.styles];
var RenderType_PeopleListWidgetComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PeopleListWidgetComponent, data: {} });
export { RenderType_PeopleListWidgetComponent as RenderType_PeopleListWidgetComponent };
function View_PeopleListWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "separator"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [","]))], null, null); }
function View_PeopleListWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PeopleListWidgetComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.urls.mediaItem(_v.context.$implicit); _ck(_v, 2, 0, currVal_2); var currVal_4 = !_v.context.last; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_3); }); }
export function View_PeopleListWidgetComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_PeopleListWidgetComponent_1)), i1.ɵdid(1, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.people, 0, _co.limit)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PeopleListWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "people-list-widget", [], null, null, null, View_PeopleListWidgetComponent_0, RenderType_PeopleListWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i4.PeopleListWidgetComponent, [i5.TitleUrlsService], null, null)], null, null); }
var PeopleListWidgetComponentNgFactory = i1.ɵccf("people-list-widget", i4.PeopleListWidgetComponent, View_PeopleListWidgetComponent_Host_0, { people: "people", limit: "limit" }, {}, []);
export { PeopleListWidgetComponentNgFactory as PeopleListWidgetComponentNgFactory };
