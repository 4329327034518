/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./star-rating-overlay.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../common/core/translations/translate.pipe";
import * as i3 from "../../../../../../common/core/translations/translations.service";
import * as i4 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/common";
import * as i9 from "@angular/material/tooltip";
import * as i10 from "@angular/cdk/overlay";
import * as i11 from "@angular/cdk/scrolling";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "@angular/platform-browser";
import * as i15 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i16 from "@angular/material/icon";
import * as i17 from "../../../../reviews/star-rating-widget/star-rating-widget.component.ngfactory";
import * as i18 from "../../../../reviews/star-rating-widget/star-rating-widget.component";
import * as i19 from "@angular/forms";
import * as i20 from "./star-rating-overlay.component";
import * as i21 from "../../../../../../common/core/ui/overlay-panel/overlay-panel-data";
import * as i22 from "../../../../../../common/core/ui/overlay-panel/overlay-panel-ref";
import * as i23 from "../../../../shared/review.service";
import * as i24 from "@ngxs/store";
var styles_StarRatingOverlayComponent = [i0.styles];
var RenderType_StarRatingOverlayComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_StarRatingOverlayComponent, data: {} });
export { RenderType_StarRatingOverlayComponent as RenderType_StarRatingOverlayComponent };
export function View_StarRatingOverlayComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.TransPipe, [i3.Translations]), (_l()(), i1.ɵeld(1, 16777216, null, null, 6, "button", [["class", "delete-button"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.deleteRating() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵdid(4, 212992, null, 0, i9.MatTooltip, [i10.Overlay, i1.ElementRef, i11.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i12.Platform, i6.AriaDescriber, i6.FocusMonitor, i9.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i13.Directionality], [2, i9.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i14.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "delete"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i15.View_MatIcon_0, i15.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i16.MatIcon, [i1.ElementRef, i16.MatIconRegistry, [8, null], [2, i16.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "star-rating-widget", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseleave" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).clearRating() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_StarRatingWidgetComponent_0, i17.RenderType_StarRatingWidgetComponent)), i1.ɵdid(9, 49152, null, 0, i18.StarRatingWidgetComponent, [], null, null), i1.ɵprd(1024, null, i19.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i18.StarRatingWidgetComponent]), i1.ɵdid(11, 540672, null, 0, i19.FormControlDirective, [[8, null], [8, null], [6, i19.NG_VALUE_ACCESSOR], [2, i19.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i19.NgControl, null, [i19.FormControlDirective]), i1.ɵdid(13, 16384, null, 0, i19.NgControlStatus, [[4, i19.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.loading$)); _ck(_v, 2, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), "Delete")); _ck(_v, 4, 0, currVal_3); var currVal_6 = "delete"; _ck(_v, 7, 0, currVal_6); var currVal_14 = _co.rating; _ck(_v, 11, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 7).inline; var currVal_5 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_8 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_9 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_10 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_11 = i1.ɵnov(_v, 13).ngClassValid; var currVal_12 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_13 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }); }
export function View_StarRatingOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "star-rating-overlay", [], null, null, null, View_StarRatingOverlayComponent_0, RenderType_StarRatingOverlayComponent)), i1.ɵdid(1, 114688, null, 0, i20.StarRatingOverlayComponent, [i21.OVERLAY_PANEL_DATA, i22.OverlayPanelRef, i23.ReviewService, i24.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StarRatingOverlayComponentNgFactory = i1.ɵccf("star-rating-overlay", i20.StarRatingOverlayComponent, View_StarRatingOverlayComponent_Host_0, {}, {}, []);
export { StarRatingOverlayComponentNgFactory as StarRatingOverlayComponentNgFactory };
