import { AppHttpClient } from '../../../../../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../common/core/http/app-http-client.service";
export class SeasonService {
    constructor(http) {
        this.http = http;
    }
    create(titleId) {
        return this.http.post('titles/' + titleId + '/seasons');
    }
    delete(id) {
        return this.http.delete('seasons/' + id);
    }
}
SeasonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SeasonService_Factory() { return new SeasonService(i0.ɵɵinject(i1.AppHttpClient)); }, token: SeasonService, providedIn: "root" });
