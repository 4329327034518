import { User } from '../core/types/models/User';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
export class CurrentUser {
    constructor() {
        this.model$ = new BehaviorSubject(null);
        this.permissions = [];
        this.isLoggedIn$ = this.model$.pipe(map(u => !!u.id));
    }
    get(prop) {
        return this.model$.value && this.model$.value[prop];
    }
    getModel() {
        return Object.assign({}, this.model$.value);
    }
    set(key, value) {
        this.model$.next(Object.assign({}, this.model$.value, { [key]: value }));
    }
    assignCurrent(model) {
        this.clear();
        if (model) {
            this.permissions = (model.permissions || []).map(p => p.name);
            this.model$.next(model);
        }
        else {
            this.clear();
        }
    }
    hasPermissions(permissions) {
        return permissions.filter(permission => {
            return !this.hasPermission(permission);
        }).length === 0;
    }
    hasPermission(permission) {
        return this.permissions.includes('admin') || this.permissions.includes(permission);
    }
    hasRole(role) {
        return this.model$.value.roles && !!this.model$.value.roles.find(r => r.name === role);
    }
    isLoggedIn() {
        return this.get('id') > 0;
    }
    /**
     * Check if user subscription is active, on trial, or on grace period.
     */
    isSubscribed() {
        if (!this.model$.value.subscriptions)
            return false;
        return this.model$.value.subscriptions.find(sub => sub.valid) !== undefined;
    }
    /**
     * Check if user subscription is active
     */
    subscriptionIsActive() {
        return this.isSubscribed() && !this.onTrial();
    }
    onTrial() {
        const sub = this.getSubscription();
        return sub && sub.on_trial;
    }
    onGracePeriod() {
        const sub = this.getSubscription();
        return sub && sub.on_grace_period;
    }
    getSubscription(filters = {}) {
        if (!this.isSubscribed())
            return null;
        let subs = this.model$.value.subscriptions.slice();
        if (filters.gateway) {
            subs = subs.filter(sub => sub.gateway === filters.gateway);
        }
        if (filters.planId) {
            subs = subs.filter(sub => sub.plan_id === filters.planId);
        }
        return subs[0];
    }
    setSubscription(subscription) {
        const i = this.model$.value.subscriptions.findIndex(sub => sub.id === subscription.id);
        if (i > -1) {
            this.model$.value.subscriptions[i] = subscription;
        }
        else {
            this.model$.value.subscriptions.push(subscription);
        }
    }
    isAdmin() {
        return this.hasPermission('admin');
    }
    clear() {
        this.permissions = this.guestsRole.permissions.map(p => p.name);
        this.model$.next(new User({ roles: [this.guestsRole] }));
    }
    init(params) {
        this.guestsRole = params.guestsRole;
        this.assignCurrent(params.user);
    }
}
CurrentUser.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentUser_Factory() { return new CurrentUser(); }, token: CurrentUser, providedIn: "root" });
