import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { ToggleGlobalLoader } from './app-state-actions';
let AppState = class AppState {
    static loading(state) {
        return state.loading;
    }
    toggleGlobalLoader(ctx, action) {
        ctx.patchState({ loading: action.visible });
    }
};
tslib_1.__decorate([
    Action(ToggleGlobalLoader),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ToggleGlobalLoader]),
    tslib_1.__metadata("design:returntype", void 0)
], AppState.prototype, "toggleGlobalLoader", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], AppState, "loading", null);
AppState = tslib_1.__decorate([
    State({
        name: 'app',
        defaults: {
            loading: false,
        }
    })
], AppState);
export { AppState };
