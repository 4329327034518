import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { HomepageService } from '../homepage.service';
import { ChangeSlide, ChangeSliderFocusState, LoadLists } from './homepage-state.actions';
let HomepageState = class HomepageState {
    constructor(homepage) {
        this.homepage = homepage;
    }
    static sliderList(state) {
        return state.lists[0];
    }
    static activeSlide(state) {
        return state.slider.activeSlide;
    }
    static autoSlide(state) {
        return state.slider.autoSlide;
    }
    static slideCount(state) {
        return state.slider.slideCount;
    }
    static focused(state) {
        return state.slider.focused;
    }
    static content(state) {
        return state.lists.slice(1);
    }
    loadLists(ctx) {
        if (ctx.getState().contentLoaded)
            return;
        return this.homepage.getLists().pipe(tap(response => {
            const sliderList = response.lists[0];
            ctx.patchState({
                lists: response.lists,
                metaTags: response.seo,
                contentLoaded: true,
                slider: Object.assign({}, ctx.getState().slider, { slideCount: sliderList ? sliderList.items.length : 0 }),
            });
        }));
    }
    changeSlide(ctx, action) {
        const lastSlide = ctx.getState().slider.slideCount - 1;
        let index = action.index;
        if (action.index > lastSlide) {
            index = 0;
        }
        else if (action.index < 0) {
            index = lastSlide;
        }
        return ctx.patchState({
            slider: Object.assign({}, ctx.getState().slider, { activeSlide: index })
        });
    }
    changeSliderFocusState(ctx, action) {
        return ctx.patchState({
            slider: Object.assign({}, ctx.getState().slider, { focused: action.state })
        });
    }
};
tslib_1.__decorate([
    Action(LoadLists),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], HomepageState.prototype, "loadLists", null);
tslib_1.__decorate([
    Action(ChangeSlide),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ChangeSlide]),
    tslib_1.__metadata("design:returntype", void 0)
], HomepageState.prototype, "changeSlide", null);
tslib_1.__decorate([
    Action(ChangeSliderFocusState),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ChangeSliderFocusState]),
    tslib_1.__metadata("design:returntype", void 0)
], HomepageState.prototype, "changeSliderFocusState", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], HomepageState, "sliderList", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], HomepageState, "activeSlide", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], HomepageState, "autoSlide", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], HomepageState, "slideCount", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], HomepageState, "focused", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], HomepageState, "content", null);
HomepageState = tslib_1.__decorate([
    State({
        name: 'homepage',
        defaults: {
            slider: {
                activeSlide: 0,
                autoSlide: false,
                slideCount: 0,
                focused: false,
            },
            contentLoaded: false,
            lists: [],
            metaTags: [],
        }
    }),
    tslib_1.__metadata("design:paramtypes", [HomepageService])
], HomepageState);
export { HomepageState };
