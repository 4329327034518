import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { Reset, SearchEverything, SetQuery } from './search-state-actions';
import { SearchService } from '../search.service';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MEDIA_TYPE } from '../../media-type';
let SearchState = class SearchState {
    constructor(search) {
        this.search = search;
    }
    static results(state) {
        return state.results;
    }
    static movies(state) {
        return state.results.filter(result => result.type === MEDIA_TYPE.TITLE && !result.is_series);
    }
    static series(state) {
        return state.results.filter(result => result.type === MEDIA_TYPE.TITLE && result.is_series);
    }
    static people(state) {
        return state.results.filter(result => result.type === MEDIA_TYPE.PERSON);
    }
    static query(state) {
        return state.query;
    }
    searchEverything(ctx, { query, type, limit }) {
        ctx.patchState({ query: query });
        if (!query || query.length < 3) {
            ctx.patchState({ results: [] });
            return of({ results: [] });
        }
        return this.search.everything(query, { type, limit }).pipe(tap(response => {
            ctx.patchState({ results: response.results });
        }));
    }
    setQuery(ctx, action) {
        ctx.patchState({ query: action.query });
    }
    reset(ctx) {
        ctx.patchState({ results: [], query: '' });
    }
};
tslib_1.__decorate([
    Action(SearchEverything),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SearchEverything]),
    tslib_1.__metadata("design:returntype", void 0)
], SearchState.prototype, "searchEverything", null);
tslib_1.__decorate([
    Action(SetQuery),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetQuery]),
    tslib_1.__metadata("design:returntype", void 0)
], SearchState.prototype, "setQuery", null);
tslib_1.__decorate([
    Action(Reset),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SearchState.prototype, "reset", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SearchState, "results", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SearchState, "movies", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SearchState, "series", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SearchState, "people", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SearchState, "query", null);
SearchState = tslib_1.__decorate([
    State({
        name: 'search',
        defaults: {
            results: [],
            query: '',
        }
    }),
    tslib_1.__metadata("design:paramtypes", [SearchService])
], SearchState);
export { SearchState };
