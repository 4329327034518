/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-grid.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "../../titles/components/genre-widget/genre-widget.component.ngfactory";
import * as i9 from "../../titles/components/genre-widget/genre-widget.component";
import * as i10 from "../media-image/media-image.component.ngfactory";
import * as i11 from "../media-image/media-image.component";
import * as i12 from "../../../../common/core/config/settings.service";
import * as i13 from "@angular/common";
import * as i14 from "@angular/router";
import * as i15 from "../../titles/components/streamable-label/streamable-label.component.ngfactory";
import * as i16 from "../../titles/components/streamable-label/streamable-label.component";
import * as i17 from "./media-grid.component";
import * as i18 from "../../titles/title-urls.service";
import * as i19 from "@ngxs/store";
var styles_MediaGridComponent = [i0.styles];
var RenderType_MediaGridComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MediaGridComponent, data: {} });
export { RenderType_MediaGridComponent as RenderType_MediaGridComponent };
function View_MediaGridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "play-button-animation"], ["mat-mini-fab", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.playVideo(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "play-arrow"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_4 = "play-arrow"; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
function View_MediaGridComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "genre-widget", [], null, null, null, i8.View_GenreWidgetComponent_0, i8.RenderType_GenreWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i9.GenreWidgetComponent, [], { genres: [0, "genres"], limit: [1, "limit"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.genres; var currVal_1 = 3; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MediaGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "figure", [["class", "item"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "media-image", [["size", "medium"]], null, null, null, i10.View_MediaImageComponent_0, i10.RenderType_MediaImageComponent)), i1.ɵdid(2, 49152, null, 0, i11.MediaImageComponent, [i12.Settings], { link: [0, "link"], size: [1, "size"], alt: [2, "alt"], src: [3, "src"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MediaGridComponent_2)), i1.ɵdid(4, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "figcaption", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "title"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i14.RouterLinkWithHref, [i14.Router, i14.ActivatedRoute, i13.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaGridComponent_3)), i1.ɵdid(10, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "streamable-label", [], [[2, "hidden", null]], null, null, i15.View_StreamableLabelComponent_0, i15.RenderType_StreamableLabelComponent)), i1.ɵdid(12, 49152, null, 0, i16.StreamableLabelComponent, [i12.Settings], { item: [0, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.urls.mediaItem(_v.context.$implicit); var currVal_2 = "medium"; var currVal_3 = (_v.context.$implicit.name + " image"); var currVal_4 = _v.context.$implicit.poster; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = ((_co.showPlayButton && (_v.context.$implicit.type === "title")) && ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.videos == null) ? null : _v.context.$implicit.videos.length))); _ck(_v, 4, 0, currVal_5); var currVal_8 = _co.urls.mediaItem(_v.context.$implicit); _ck(_v, 7, 0, currVal_8); var currVal_10 = !_co.isPerson(_v.context.$implicit); _ck(_v, 10, 0, currVal_10); var currVal_12 = _v.context.$implicit; _ck(_v, 12, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 0, 0, currVal_0); var currVal_6 = i1.ɵnov(_v, 7).target; var currVal_7 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_6, currVal_7); var currVal_9 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_9); var currVal_11 = i1.ɵnov(_v, 12).isHidden; _ck(_v, 11, 0, currVal_11); }); }
export function View_MediaGridComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaGridComponent_1)), i1.ɵdid(1, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; var currVal_1 = _co.trackByFn; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_MediaGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "media-grid", [], null, null, null, View_MediaGridComponent_0, RenderType_MediaGridComponent)), i1.ɵdid(1, 49152, null, 0, i17.MediaGridComponent, [i18.TitleUrlsService, i19.Store], null, null)], null, null); }
var MediaGridComponentNgFactory = i1.ɵccf("media-grid", i17.MediaGridComponent, View_MediaGridComponent_Host_0, { items: "items", showPlayButton: "showPlayButton" }, { actionClick: "actionClick" }, []);
export { MediaGridComponentNgFactory as MediaGridComponentNgFactory };
