/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-image.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "ng-lazyload-image";
import * as i5 from "./media-image.component";
import * as i6 from "../../../../common/core/config/settings.service";
var styles_MediaImageComponent = [i0.styles];
var RenderType_MediaImageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MediaImageComponent, data: {} });
export { RenderType_MediaImageComponent as RenderType_MediaImageComponent };
function View_MediaImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["image", 1]], null, 1, "img", [["class", "media-image-el"], ["draggable", "false"]], [[8, "alt", 0]], null, null, null, null)), i1.ɵdid(3, 1720320, null, 0, i4.LazyLoadImageDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"], defaultImage: [1, "defaultImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.link; _ck(_v, 1, 0, currVal_2); var currVal_4 = _co.src; var currVal_5 = _co.getDefaultPoster(); _ck(_v, 3, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = (_co.alt || ""); _ck(_v, 2, 0, currVal_3); }); }
function View_MediaImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["image", 1]], null, 1, "img", [["class", "media-image-el"], ["draggable", "false"]], [[8, "alt", 0]], null, null, null, null)), i1.ɵdid(1, 1720320, null, 0, i4.LazyLoadImageDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"], defaultImage: [1, "defaultImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.src; var currVal_2 = _co.getDefaultPoster(); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.alt || ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_MediaImageComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { image: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaImageComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noLink", 2]], null, 0, null, View_MediaImageComponent_2)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_MediaImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "media-image", [], null, null, null, View_MediaImageComponent_0, RenderType_MediaImageComponent)), i1.ɵdid(1, 49152, null, 0, i5.MediaImageComponent, [i6.Settings], null, null)], null, null); }
var MediaImageComponentNgFactory = i1.ɵccf("media-image", i5.MediaImageComponent, View_MediaImageComponent_Host_0, { link: "link", size: "size", mode: "mode", alt: "alt", src: "src" }, {}, ["*"]);
export { MediaImageComponentNgFactory as MediaImageComponentNgFactory };
