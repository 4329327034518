import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { SocialAuthService } from '../social-auth.service';
import { CurrentUser } from '../current-user';
import { Router } from '@angular/router';
import { Settings } from '../../core/config/settings.service';
import { Toast } from '../../core/ui/toast.service';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { RecaptchaService } from '../../core/services/recaptcha.service';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
export class RegisterComponent {
    constructor(auth, socialAuth, settings, user, router, toast, bootstrapper, recaptcha, fb) {
        this.auth = auth;
        this.socialAuth = socialAuth;
        this.settings = settings;
        this.user = user;
        this.router = router;
        this.toast = toast;
        this.bootstrapper = bootstrapper;
        this.recaptcha = recaptcha;
        this.fb = fb;
        this.loading$ = new BehaviorSubject(false);
        this.form = this.fb.group({
            email: [''],
            password: [''],
            password_confirmation: [''],
            purchase_code: [''],
        });
        this.errors$ = new BehaviorSubject({});
    }
    ngOnInit() {
        if (this.recaptcha.enabledFor('registration')) {
            this.recaptcha.load();
        }
    }
    register() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading$.next(true);
            if (this.recaptcha.enabledFor('registration') && !(yield this.recaptcha.verify('registration'))) {
                this.loading$.next(false);
                return this.toast.open('Could not verify you are human.');
            }
            this.auth.register(this.form.value)
                .subscribe(response => {
                if (this.settings.get('require_email_confirmation')) {
                    this.router.navigate(['/login']).then(() => {
                        this.loading$.next(false);
                        this.toast.open('We have sent you an email with instructions on how to activate your account.', { duration: 6000 });
                    });
                }
                else {
                    this.bootstrapper.bootstrap(response.data);
                    this.router.navigate([this.auth.getRedirectUri()]).then(() => {
                        this.loading$.next(false);
                        this.toast.open('Registered successfully.');
                    });
                }
            }, err => {
                this.errors$.next(err.messages);
                this.loading$.next(false);
            });
        });
    }
}
