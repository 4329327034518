/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-progress-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "./upload-progress-bar.component";
import * as i7 from "../upload-queue/upload-queue.service";
var styles_UploadProgressBarComponent = [i0.styles];
var RenderType_UploadProgressBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadProgressBarComponent, data: {} });
export { RenderType_UploadProgressBarComponent as RenderType_UploadProgressBarComponent };
export function View_UploadProgressBarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["color", "accent"], ["mode", "determinate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], value: [1, "value"], mode: [2, "mode"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = "accent"; var currVal_4 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_co.uploadQueue.totalProgress())); var currVal_5 = "determinate"; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_UploadProgressBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "upload-progress-bar", [], [[2, "hidden", null]], null, null, View_UploadProgressBarComponent_0, RenderType_UploadProgressBarComponent)), i1.ɵdid(1, 180224, null, 0, i6.UploadProgressBarComponent, [i1.ElementRef, i7.UploadQueueService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).initiallyHidden; _ck(_v, 0, 0, currVal_0); }); }
var UploadProgressBarComponentNgFactory = i1.ɵccf("upload-progress-bar", i6.UploadProgressBarComponent, View_UploadProgressBarComponent_Host_0, {}, {}, []);
export { UploadProgressBarComponentNgFactory as UploadProgressBarComponentNgFactory };
