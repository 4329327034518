import * as tslib_1 from "tslib";
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { PlayVideo } from '../../../player/state/player-state-actions';
import { Settings } from '@common/core/config/settings.service';
export class MediaItemHeaderComponent {
    constructor(store, settings) {
        this.store = store;
        this.settings = settings;
        this.transparent = false;
    }
    get backgroundImage() {
        if (this.backdrop) {
            return 'url(' + this.backdrop + ')';
        }
    }
    get noBackdrop() {
        if (!this.backdrop) {
            return 'no-backdrop';
        }
    }
    playVideo(video) {
        const title = this.store.selectSnapshot(TitleState.title);
        this.store.dispatch(new PlayVideo(video, title));
    }
}
tslib_1.__decorate([
    Select(TitleState.primaryVideo),
    tslib_1.__metadata("design:type", Observable)
], MediaItemHeaderComponent.prototype, "primaryVideo$", void 0);
