import * as tslib_1 from "tslib";
import { Action, Selector, State, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { TitlesService } from '../../../../site/titles/titles.service';
import { AddCredit, AddImage, AddVideo, ChangeCreditOrder, ChangeImageOrder, ChangeVideosOrder, CreateEpisode, CreateSeason, CreateTag, CreateTitle, DeleteEpisode, DeleteImage, DeleteSeason, DeleteVideo, DetachTag, HydrateTitle, LoadEpisodeCredits, LoadSelectOptions, RemoveCredit, ResetState, UpdateCredit, UpdateEpisode, UpdateTitle, UpdateVideo } from './crupdate-title-actions';
import { Title } from '../../../../models/title';
import { Toast } from '../../../../../common/core/ui/toast.service';
import { MESSAGES } from '../../../../toast-messages';
import { Navigate } from '@ngxs/router-plugin';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { VideoService } from '../../../../site/videos/video.service';
import { ValueLists } from '../../../../../common/core/services/value-lists.service';
import { SeasonService } from '../panels/seasons-panel/season.service';
import { MEDIA_TYPE } from '../../../../site/media-type';
import { ImagesService } from '../../../../site/shared/images.service';
let CrupdateTitleState = class CrupdateTitleState {
    constructor(router, store, titles, toast, videos, images, valueLists, seasons) {
        this.router = router;
        this.store = store;
        this.titles = titles;
        this.toast = toast;
        this.videos = videos;
        this.images = images;
        this.valueLists = valueLists;
        this.seasons = seasons;
    }
    static title(state) {
        return state.title;
    }
    static loading(state) {
        return state.loading;
    }
    static keywords(state) {
        return state.title.keywords;
    }
    static genres(state) {
        return state.title.genres;
    }
    static countries(state) {
        return state.title.countries;
    }
    static images(state) {
        return state.title.images;
    }
    static seasons(state) {
        return state.title.seasons;
    }
    static videos(state) {
        return state.title.videos;
    }
    static languageOptions(state) {
        return state.selectOptions.languages;
    }
    hydrateTitle(ctx, action) {
        ctx.patchState({ loading: true });
        const query = { fullCredits: true, keywords: true, countries: true, seasons: true, skipUpdating: true, allVideos: true };
        return this.titles.get(action.id, query).pipe(tap(response => {
            ctx.patchState({
                title: response.title,
                loading: false
            });
        }));
    }
    loadSelectOptions(ctx) {
        this.valueLists.get(['languages']).subscribe(response => {
            ctx.patchState({
                selectOptions: {
                    languages: response.languages,
                }
            });
        });
    }
    createSeason(ctx) {
        ctx.patchState({ loading: true });
        return this.seasons.create(ctx.getState().title.id).pipe(tap(response => {
            const title = Object.assign({}, ctx.getState().title, { seasons: [...ctx.getState().title.seasons, response.season] });
            ctx.patchState({ title });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    deleteSeason(ctx, action) {
        ctx.patchState({ loading: true });
        return this.seasons.delete(action.season.id).pipe(tap(() => {
            const newSeasons = ctx.getState().title.seasons.filter(s => {
                return s.id !== action.season.id;
            });
            const title = Object.assign({}, ctx.getState().title, { seasons: newSeasons });
            ctx.patchState({ title });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    crupdateTitle(ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.update(ctx.getState().title.id, action.payload).pipe(tap(() => {
            this.toast.open(MESSAGES.TITLE_UPDATE_SUCCESS);
            this.store.dispatch(new Navigate(['/admin/titles']));
        }, () => {
            this.toast.open(MESSAGES.TITLE_UPDATE_FAILED);
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    createTitle(ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.create(action.payload).pipe(tap(response => {
            this.toast.open(MESSAGES.TITLE_CREATE_SUCCESS);
            this.store.dispatch(new Navigate(['/admin/titles', response.title.id, 'edit']));
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    updateCredit(ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.updateCredit(action.id, action.credit).pipe(tap(response => {
            // update matching credit on the state
            const newCredits = ctx.getState().title.credits.map(credit => {
                if (credit.pivot.id === response.credit.id) {
                    credit.pivot = response.credit;
                }
                return credit;
            });
            ctx.patchState({ title: Object.assign({}, ctx.getState().title, { credits: newCredits }) });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    addCredit(ctx, action) {
        ctx.patchState({ loading: true });
        const creditablePayload = { type: action.creditable.type, id: action.creditable.id };
        return this.titles.addCredit(action.personId, creditablePayload, action.pivot).pipe(tap(response => {
            if (action.creditable.type === MEDIA_TYPE.TITLE) {
                const newCredits = [...ctx.getState().title.credits, response.credit];
                ctx.patchState({ title: Object.assign({}, ctx.getState().title, { credits: newCredits }) });
            }
            else if (action.creditable.type === MEDIA_TYPE.SEASON) {
                const seasons = ctx.getState().title.seasons.map(season => {
                    const newSeason = Object.assign({}, season);
                    if (newSeason.id === action.creditable.id) {
                        newSeason.credits = [...newSeason.credits, response.credit];
                    }
                    return newSeason;
                });
                ctx.patchState({ title: Object.assign({}, ctx.getState().title, { seasons }) });
            }
            else if (action.creditable.type === MEDIA_TYPE.EPISODE) {
                const creditable = action.creditable;
                const seasons = ctx.getState().title.seasons.map(season => {
                    if (season.number === creditable.season_number) {
                        season.episodes.map(episode => {
                            const newEpisode = Object.assign({}, episode);
                            if (newEpisode.id === action.creditable.id) {
                                newEpisode.credits = [...newEpisode.credits, response.credit];
                            }
                            return newEpisode;
                        });
                    }
                    return Object.assign({}, season);
                });
                ctx.patchState({ title: Object.assign({}, ctx.getState().title, { seasons }) });
            }
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    removeCredit(ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.removeCredit(action.credit.pivot.id).pipe(tap(() => {
            if (action.creditable.type === MEDIA_TYPE.TITLE) {
                const newCredits = ctx.getState().title.credits.filter(credit => {
                    return credit.pivot.id !== action.credit.pivot.id;
                });
                ctx.patchState({ title: Object.assign({}, ctx.getState().title, { credits: newCredits }) });
            }
            else if (action.creditable.type === MEDIA_TYPE.SEASON) {
                const seasons = ctx.getState().title.seasons.map(season => {
                    if (season.id === action.creditable.id) {
                        season.credits = season.credits.filter(c => c.id !== action.credit.id);
                    }
                    return Object.assign({}, season);
                });
                ctx.patchState({ title: Object.assign({}, ctx.getState().title, { seasons }) });
            }
            else if (action.creditable.type === MEDIA_TYPE.EPISODE) {
                const creditable = action.creditable;
                const seasons = ctx.getState().title.seasons.map(season => {
                    if (season.number === creditable.season_number) {
                        season.episodes.map(episode => {
                            if (episode.id === action.creditable.id) {
                                episode.credits = episode.credits.filter(c => c.id !== action.credit.id);
                            }
                            return Object.assign({}, episode);
                        });
                    }
                    return Object.assign({}, season);
                });
                ctx.patchState({ title: Object.assign({}, ctx.getState().title, { seasons }) });
            }
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    changeCreditOrder(ctx, action) {
        let credits = [];
        if (action.creditable.type === MEDIA_TYPE.TITLE) {
            credits = [...ctx.getState().title.credits];
            moveItemInArray(credits, action.currentIndex, action.newIndex);
            ctx.patchState({ title: Object.assign({}, ctx.getState().title, { credits }) });
        }
        else if (action.creditable.type === MEDIA_TYPE.SEASON) {
            const seasons = ctx.getState().title.seasons.map(season => {
                if (season.id === action.creditable.id) {
                    credits = season.credits;
                    moveItemInArray(credits, action.currentIndex, action.newIndex);
                }
                return Object.assign({}, season);
            });
            ctx.patchState({ title: Object.assign({}, ctx.getState().title, { seasons }) });
        }
        else if (action.creditable.type === MEDIA_TYPE.EPISODE) {
            const creditable = action.creditable;
            const seasons = ctx.getState().title.seasons.map(season => {
                if (season.number === creditable.season_number) {
                    season.episodes.map(episode => {
                        if (episode.id === action.creditable.id) {
                            credits = episode.credits;
                            moveItemInArray(credits, action.currentIndex, action.newIndex);
                        }
                        return Object.assign({}, episode);
                    });
                }
                return Object.assign({}, season);
            });
            ctx.patchState({ title: Object.assign({}, ctx.getState().title, { seasons }) });
        }
        const order = {};
        credits
            .filter(c => c.pivot.department === 'cast')
            .forEach((credit, index) => {
            order[index] = credit.pivot.id;
        });
        ctx.patchState({ loading: true });
        return this.titles.changeCreditsOrder(order).pipe(finalize(() => ctx.patchState({ loading: false })));
    }
    changeVideosOrder(ctx, action) {
        const videos = ctx.getState().title.all_videos.slice();
        moveItemInArray(videos, action.currentIndex, action.newIndex);
        ctx.patchState({ title: Object.assign({}, ctx.getState().title, { all_videos: videos }) });
        const order = {};
        videos.forEach((video, i) => order[i] = video.id);
        ctx.patchState({ loading: true });
        return this.titles.changeVideosOrder(ctx.getState().title.id, order).pipe(finalize(() => ctx.patchState({ loading: false })));
    }
    changeImageOrder(ctx, action) {
        const images = [...ctx.getState().title.images];
        moveItemInArray(images, action.currentIndex, action.newIndex);
        ctx.patchState({ title: Object.assign({}, ctx.getState().title, { images: images }) });
        const order = {};
        images.forEach((video, i) => order[i] = video.id);
        ctx.patchState({ loading: true });
        return this.titles.changeImageOrder(ctx.getState().title.id, order).pipe(finalize(() => ctx.patchState({ loading: false })));
    }
    loadEpisodeCredits(ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.getEpisode(action.episode.id).pipe(tap(response => {
            const seasons = ctx.getState().title.seasons.map(season => {
                if (season.number === action.episode.season_number) {
                    const episodes = season.episodes.map(episode => {
                        return episode.id === action.episode.id ? response.episode : episode;
                    });
                    return Object.assign({}, season, { episodes });
                }
                return season;
            });
            ctx.patchState({ title: Object.assign({}, ctx.getState().title, { seasons }) });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    deleteEpisode(ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.deleteEpisode(action.episode.id).pipe(tap(() => {
            const seasons = ctx.getState().title.seasons.map(season => {
                const newSeason = Object.assign({}, season);
                if (newSeason.number === action.episode.season_number) {
                    newSeason.episodes = newSeason.episodes.filter(episode => {
                        return episode.id !== action.episode.id;
                    });
                }
                return newSeason;
            });
            ctx.patchState({ title: Object.assign({}, ctx.getState().title, { seasons }) });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    updateEpisode(ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.updateEpisode(action.episode.id, action.payload).pipe(tap(response => {
            const seasons = ctx.getState().title.seasons.map(season => {
                if (season.number === action.episode.season_number) {
                    const episodes = season.episodes.map(episode => {
                        return episode.id === action.episode.id ? response.episode : episode;
                    });
                    return Object.assign({}, season, { episodes });
                }
                return season;
            });
            ctx.patchState({ title: Object.assign({}, ctx.getState().title, { seasons }) });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    createEpisode(ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.createEpisode(action.season.id, action.payload).pipe(tap(response => {
            const seasons = ctx.getState().title.seasons.map(season => {
                const newSeason = Object.assign({}, season);
                if (newSeason.number === response.episode.season_number) {
                    newSeason.episodes = [...newSeason.episodes, response.episode];
                }
                return newSeason;
            });
            ctx.patchState({ title: Object.assign({}, ctx.getState().title, { seasons }) });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    addVideo(ctx, action) {
        const title = Object.assign({}, ctx.getState().title, { all_videos: [...ctx.getState().title.all_videos, action.video] });
        ctx.patchState({ title });
    }
    updateVideo(ctx, action) {
        const videos = ctx.getState().title.all_videos.slice();
        const i = videos.findIndex(v => v.id === action.video.id);
        videos[i] = action.video;
        const title = Object.assign({}, ctx.getState().title, { all_videos: videos });
        ctx.patchState({ title });
    }
    deleteVideo(ctx, action) {
        ctx.patchState({ loading: true });
        return this.videos.delete([action.video.id]).pipe(tap(() => {
            const videos = ctx.getState().title.all_videos.slice();
            const i = videos.findIndex(v => v.id === action.video.id);
            videos.splice(i, 1);
            const title = Object.assign({}, ctx.getState().title, { all_videos: videos });
            ctx.patchState({ title });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    addImage(ctx, action) {
        const title = Object.assign({}, ctx.getState().title, { images: [...ctx.getState().title.images, action.image] });
        ctx.patchState({ title });
    }
    deleteImage(ctx, action) {
        ctx.patchState({ loading: true });
        return this.images.delete(action.image.id).pipe(tap(() => {
            const images = ctx.getState().title.images.slice();
            const i = images.findIndex(v => v.id === action.image.id);
            images.splice(i, 1);
            const title = Object.assign({}, ctx.getState().title, { images });
            ctx.patchState({ title });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    createTag(ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.createTag(ctx.getState().title.id, action.tag).pipe(tap(response => {
            const type = action.tag.type === 'keyword' ? 'keywords' : 'genres';
            const oldTags = ctx.getState().title[type];
            const exists = oldTags.find(tag => tag.id === response.tag.id);
            if (exists)
                return;
            ctx.patchState({
                title: Object.assign({}, ctx.getState().title, { [type]: [...oldTags, response.tag] })
            });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    detachTag(ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.detachTag(ctx.getState().title.id, action.tag).pipe(tap(() => {
            const type = action.tag.type === 'keyword' ? 'keywords' : 'genres';
            const oldTags = ctx.getState().title[type];
            const i = oldTags.findIndex(tag => tag.id === action.tag.id);
            if (i > -1)
                oldTags.splice(i, 1);
            ctx.patchState({
                title: Object.assign({}, ctx.getState().title, { [type]: oldTags.slice() })
            });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    resetState(ctx) {
        ctx.patchState({
            title: new Title(),
            loading: false,
        });
    }
};
tslib_1.__decorate([
    Action(HydrateTitle),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, HydrateTitle]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "hydrateTitle", null);
tslib_1.__decorate([
    Action(LoadSelectOptions),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "loadSelectOptions", null);
tslib_1.__decorate([
    Action(CreateSeason),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "createSeason", null);
tslib_1.__decorate([
    Action(DeleteSeason),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DeleteSeason]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "deleteSeason", null);
tslib_1.__decorate([
    Action(UpdateTitle),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateTitle]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "crupdateTitle", null);
tslib_1.__decorate([
    Action(CreateTitle),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CreateTitle]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "createTitle", null);
tslib_1.__decorate([
    Action(UpdateCredit),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateCredit]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "updateCredit", null);
tslib_1.__decorate([
    Action(AddCredit),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddCredit]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "addCredit", null);
tslib_1.__decorate([
    Action(RemoveCredit),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, RemoveCredit]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "removeCredit", null);
tslib_1.__decorate([
    Action(ChangeCreditOrder),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ChangeCreditOrder]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "changeCreditOrder", null);
tslib_1.__decorate([
    Action(ChangeVideosOrder),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ChangeVideosOrder]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "changeVideosOrder", null);
tslib_1.__decorate([
    Action(ChangeImageOrder),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ChangeImageOrder]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "changeImageOrder", null);
tslib_1.__decorate([
    Action(LoadEpisodeCredits),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, LoadEpisodeCredits]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "loadEpisodeCredits", null);
tslib_1.__decorate([
    Action(DeleteEpisode),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DeleteEpisode]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "deleteEpisode", null);
tslib_1.__decorate([
    Action(UpdateEpisode),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateEpisode]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "updateEpisode", null);
tslib_1.__decorate([
    Action(CreateEpisode),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CreateEpisode]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "createEpisode", null);
tslib_1.__decorate([
    Action(AddVideo),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddVideo]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "addVideo", null);
tslib_1.__decorate([
    Action(UpdateVideo),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateVideo]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "updateVideo", null);
tslib_1.__decorate([
    Action(DeleteVideo),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DeleteVideo]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "deleteVideo", null);
tslib_1.__decorate([
    Action(AddImage),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddImage]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "addImage", null);
tslib_1.__decorate([
    Action(DeleteImage),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DeleteImage]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "deleteImage", null);
tslib_1.__decorate([
    Action(CreateTag),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CreateTag]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "createTag", null);
tslib_1.__decorate([
    Action(DetachTag),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DetachTag]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "detachTag", null);
tslib_1.__decorate([
    Action(ResetState),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState.prototype, "resetState", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState, "title", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState, "loading", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState, "keywords", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState, "genres", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState, "countries", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState, "images", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState, "seasons", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState, "videos", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdateTitleState, "languageOptions", null);
CrupdateTitleState = tslib_1.__decorate([
    State({
        name: 'crupdateTitle',
        defaults: {
            title: new Title(),
            loading: false,
            selectOptions: {
                languages: [],
            }
        },
    }),
    tslib_1.__metadata("design:paramtypes", [Router,
        Store,
        TitlesService,
        Toast,
        VideoService,
        ImagesService,
        ValueLists,
        SeasonService])
], CrupdateTitleState);
export { CrupdateTitleState };
