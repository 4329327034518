import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PersonState } from '../state/person-state';
import { Observable } from 'rxjs';
import { TitleUrlsService } from '../../titles/title-urls.service';
import { ToggleGlobalLoader } from '../../../state/app-state-actions';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
export class PersonPageComponent {
    constructor(urls, store, route, viewportScroller) {
        this.urls = urls;
        this.store = store;
        this.route = route;
        this.viewportScroller = viewportScroller;
    }
    ngOnInit() {
        this.route.params.subscribe(() => {
            this.viewportScroller.scrollToPosition([0, 0]);
            this.store.dispatch(new ToggleGlobalLoader(false));
        });
    }
    trackByFn(title) {
        return title.id;
    }
}
tslib_1.__decorate([
    Select(PersonState.person),
    tslib_1.__metadata("design:type", Observable)
], PersonPageComponent.prototype, "person$", void 0);
tslib_1.__decorate([
    Select(PersonState.credits),
    tslib_1.__metadata("design:type", Observable)
], PersonPageComponent.prototype, "credits$", void 0);
tslib_1.__decorate([
    Select(PersonState.knownFor),
    tslib_1.__metadata("design:type", Observable)
], PersonPageComponent.prototype, "knownFor$", void 0);
tslib_1.__decorate([
    Select(PersonState.backdrop),
    tslib_1.__metadata("design:type", Observable)
], PersonPageComponent.prototype, "backdrop$", void 0);
tslib_1.__decorate([
    Select(PersonState.creditsCount),
    tslib_1.__metadata("design:type", Observable)
], PersonPageComponent.prototype, "creditsCount$", void 0);
