<ng-container *ngIf="title$ | async as title">
    <section class="left">
        <div class="img-container">
            <media-image [src]="title.poster" size="medium"></media-image>
        </div>
        <div class="details">
            <div class="detail seasons" *ngIf="isSeries()">
                <div class="name" trans>Seasons</div>
                <div class="value">
                    <a class="season" [routerLink]="['season', season.number]" *ngFor="let season of seasons$ | async">{{season.number}}</a>
                </div>
            </div>
            <div class="detail" *ngIf="credits.director">
                <div class="name" trans>Director</div>
                <div class="value">
                    <people-list-widget [people]="[credits.director]" [limit]="1"></people-list-widget>
                </div>
            </div>
            <div class="detail" *ngIf="credits.creators?.length">
                <div class="name" trans>Created By</div>
                <div class="value">
                    <people-list-widget [people]="credits.creators" [limit]="3"></people-list-widget>
                </div>
            </div>
            <div class="detail" *ngIf="credits.writers?.length">
                <div class="name" trans>Writers</div>
                <div class="value">
                    <people-list-widget [people]="credits.writers" [limit]="3"></people-list-widget>
                </div>
            </div>
            <div class="detail with-separator">
                <div class="name" trans>Cast</div>
                <div class="value">
                    <people-list-widget *ngIf="credits.cast.length; else noCast" [people]="credits.cast" [limit]="5"></people-list-widget>
                    <ng-template #noCast>{{'No cast information has been added yet.' | trans}}</ng-template>
                </div>
            </div>
            <div class="detail">
                <div class="name" trans>Plot</div>
                <div class="value">
                    <span moreLessText="340" [textContent]="item.description" *ngIf="item.description; else noDescription"></span>
                    <ng-template #noDescription>{{'No overview has been added yet.' | trans}}</ng-template>
                </div>
            </div>
        </div>
    </section>

    <section class="right">
        <title-action-buttons [item]="title"></title-action-buttons>
        <div class="video-cover">
            <media-image [src]="videoCoverImage$ | async" size="large" mode="landscape"></media-image>
            <button mat-fab (click)="playVideo(primaryVideo)" class="play-button-animation" *ngIf="primaryVideo$ | async as primaryVideo">
                <mat-icon svgIcon="play-arrow"></mat-icon>
            </button>
        </div>
    </section>
</ng-container>