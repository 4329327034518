import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class ListsService {
    constructor(http) {
        this.http = http;
    }
    get(id, params) {
        return this.http.get('lists/' + id, params);
    }
    getAll(params) {
        return this.http.get('lists', params);
    }
    update(id, payload) {
        return this.http.put('lists/' + id, payload);
    }
    create(payload) {
        return this.http.post('lists', payload);
    }
    addItem(listId, mediaItem) {
        return this.http.post('lists/' + listId + '/add', { itemId: mediaItem.id, itemType: mediaItem.type });
    }
    removeItem(listId, mediaItem) {
        return this.http.post('lists/' + listId + '/remove', { itemId: mediaItem.id, itemType: mediaItem.type });
    }
    reorder(listId, itemIds) {
        return this.http.post('lists/' + listId + '/reorder', { itemIds });
    }
    delete(listIds) {
        return this.http.delete('lists', { listIds });
    }
}
ListsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ListsService_Factory() { return new ListsService(i0.ɵɵinject(i1.AppHttpClient)); }, token: ListsService, providedIn: "root" });
