/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./title-cast-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/media-image/media-image.component.ngfactory";
import * as i3 from "../../../shared/media-image/media-image.component";
import * as i4 from "../../../../../common/core/config/settings.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../common/core/translations/translate.directive";
import * as i8 from "../../../../../common/core/translations/translations.service";
import * as i9 from "./title-cast-panel.component";
import * as i10 from "../../title-urls.service";
var styles_TitleCastPanelComponent = [i0.styles];
var RenderType_TitleCastPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TitleCastPanelComponent, data: {} });
export { RenderType_TitleCastPanelComponent as RenderType_TitleCastPanelComponent };
function View_TitleCastPanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "separator"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["..."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "cast-table-col character-col"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.pivot.character; _ck(_v, 4, 0, currVal_0); }); }
function View_TitleCastPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "cast-table-row"]], [[2, "even", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "cast-table-col image-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "media-image", [["size", "small"]], null, null, null, i2.View_MediaImageComponent_0, i2.RenderType_MediaImageComponent)), i1.ɵdid(3, 49152, null, 0, i3.MediaImageComponent, [i4.Settings], { size: [0, "size"], src: [1, "src"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["class", "cast-table-col name-col"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleCastPanelComponent_3)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "small"; var currVal_2 = _v.context.$implicit.poster; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_5 = _co.urls.mediaItem(_v.context.$implicit); _ck(_v, 5, 0, currVal_5); var currVal_7 = _v.context.$implicit.pivot.character; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.context.even; _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 5).target; var currVal_4 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_6); }); }
function View_TitleCastPanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i7.TranslateDirective, [i1.ElementRef, i8.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["No cast information has been added yet."]))], null, null); }
function View_TitleCastPanelComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "full-cast-button"], ["routerLink", "full-credits"], ["trans", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i7.TranslateDirective, [i1.ElementRef, i8.Translations, i4.Settings], null, null), i1.ɵdid(2, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["See full cast >>"]))], function (_ck, _v) { var currVal_2 = "full-credits"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TitleCastPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TitleCastPanelComponent_2)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i6.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleCastPanelComponent_4)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleCastPanelComponent_5)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.ngIf, 0, 10)); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.ngIf.length; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.ngIf.length; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_TitleCastPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_TitleCastPanelComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.cast$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TitleCastPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "title-cast-panel", [], null, null, null, View_TitleCastPanelComponent_0, RenderType_TitleCastPanelComponent)), i1.ɵdid(1, 49152, null, 0, i9.TitleCastPanelComponent, [i10.TitleUrlsService], null, null)], null, null); }
var TitleCastPanelComponentNgFactory = i1.ɵccf("title-cast-panel", i9.TitleCastPanelComponent, View_TitleCastPanelComponent_Host_0, {}, {}, []);
export { TitleCastPanelComponentNgFactory as TitleCastPanelComponentNgFactory };
