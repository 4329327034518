import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
const BASE_URI = 'videos';
export class VideoService {
    constructor(http) {
        this.http = http;
    }
    create(payload) {
        return this.http.post(BASE_URI, payload);
    }
    update(id, payload) {
        return this.http.put(`${BASE_URI}/${id}`, payload);
    }
    delete(ids) {
        return this.http.delete(`${BASE_URI}/${ids}`);
    }
    rate(id, rating) {
        return this.http.post(`${BASE_URI}/${id}/rate`, { rating });
    }
    approve(id) {
        return this.http.post(`${BASE_URI}/${id}/approve`);
    }
    disapprove(id) {
        return this.http.post(`${BASE_URI}/${id}/disapprove`);
    }
    report(id) {
        return this.http.post(`${BASE_URI}/${id}/report`);
    }
}
VideoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VideoService_Factory() { return new VideoService(i0.ɵɵinject(i1.AppHttpClient)); }, token: VideoService, providedIn: "root" });
