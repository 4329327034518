import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { Person } from '../../../../models/person';
import { finalize, tap } from 'rxjs/operators';
import { CreatePerson, DetachCredit, LoadPerson, ResetState, UpdatePerson } from './crupdate-person-state-actions';
import { PeopleService } from '../../../../site/people/people.service';
import { TitlesService } from '../../../../site/titles/titles.service';
let CrupdatePersonState = class CrupdatePersonState {
    constructor(people, titles) {
        this.people = people;
        this.titles = titles;
    }
    static person(state) {
        return state.person;
    }
    static loading(state) {
        return state.loading;
    }
    static credits(state) {
        return state.credits;
    }
    loadPerson(ctx, action) {
        ctx.patchState({ loading: true });
        return this.people.get(action.id).pipe(tap(response => {
            ctx.patchState({
                person: response.person,
                credits: this.flattenCredits(response.credits),
                loading: false
            });
        }));
    }
    createPerson(ctx, action) {
        ctx.patchState({ loading: true });
        return this.people.create(action.payload).pipe(finalize(() => ctx.patchState({ loading: false })), tap(response => ctx.patchState({ person: response.person })));
    }
    updatePerson(ctx, action) {
        ctx.patchState({ loading: true });
        return this.people.update(ctx.getState().person.id, action.payload).pipe(finalize(() => ctx.patchState({ loading: false })), tap(response => ctx.patchState({ person: response.person })));
    }
    removeCredit(ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.removeCredit(action.id).pipe(tap(() => {
            const newCredits = ctx.getState().credits.filter(credit => {
                return credit.pivot.id !== action.id;
            });
            ctx.patchState({ credits: newCredits });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    resetState(ctx) {
        ctx.patchState({
            person: new Person(),
            credits: [],
            loading: false,
        });
    }
    flattenCredits(credits) {
        const flatCredits = [];
        Object.keys(credits).forEach(key => {
            flatCredits.push(...credits[key]);
        });
        return flatCredits;
    }
};
tslib_1.__decorate([
    Action(LoadPerson),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, LoadPerson]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdatePersonState.prototype, "loadPerson", null);
tslib_1.__decorate([
    Action(CreatePerson),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CreatePerson]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdatePersonState.prototype, "createPerson", null);
tslib_1.__decorate([
    Action(UpdatePerson),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdatePerson]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdatePersonState.prototype, "updatePerson", null);
tslib_1.__decorate([
    Action(DetachCredit),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DetachCredit]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdatePersonState.prototype, "removeCredit", null);
tslib_1.__decorate([
    Action(ResetState),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdatePersonState.prototype, "resetState", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdatePersonState, "person", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdatePersonState, "loading", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CrupdatePersonState, "credits", null);
CrupdatePersonState = tslib_1.__decorate([
    State({
        name: 'crupdatePerson',
        defaults: {
            person: new Person(),
            credits: [],
            loading: false,
        },
    }),
    tslib_1.__metadata("design:paramtypes", [PeopleService,
        TitlesService])
], CrupdatePersonState);
export { CrupdatePersonState };
