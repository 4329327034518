import * as tslib_1 from "tslib";
import { TitleUrlsService } from '../../title-urls.service';
import { Select } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
export class TitleCastPanelComponent {
    constructor(urls) {
        this.urls = urls;
    }
}
tslib_1.__decorate([
    Select(TitleState.titleOrEpisodeCast),
    tslib_1.__metadata("design:type", Observable)
], TitleCastPanelComponent.prototype, "cast$", void 0);
