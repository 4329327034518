import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PlayerState } from '../state/player-state';
import { BehaviorSubject, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { PlayerOverlayClosed, PlayerOverlayOpened, PlayVideo, ToggleSidebar } from '../state/player-state-actions';
import { LazyLoaderService } from '@common/core/utils/lazy-loader.service';
import { OverlayPanelRef } from '@common/core/ui/overlay-panel/overlay-panel-ref';
import { BreakpointsService } from '@common/core/ui/breakpoints.service';
import { Settings } from '@common/core/config/settings.service';
export class PlayerComponent {
    constructor(store, sanitizer, overlayRef, breakpoints, lazyLoader, settings) {
        this.store = store;
        this.sanitizer = sanitizer;
        this.overlayRef = overlayRef;
        this.breakpoints = breakpoints;
        this.lazyLoader = lazyLoader;
        this.settings = settings;
        this.loading$ = new BehaviorSubject(false);
        this.iframeUrl$ = new BehaviorSubject(null);
        this.minimized = false;
        this.trackByFn = (i, video) => video.id;
    }
    ngOnInit() {
        this.store.dispatch(new PlayerOverlayOpened());
        this.activeVideo$.subscribe(video => {
            if (this.shouldUsePlyr(video)) {
                this.loadPlyr(video);
                this.iframeUrl$.next(null);
            }
            else {
                this.plyr && this.plyr.stop();
                if (video.type === 'external') {
                    this.iframeUrl$.next(null);
                    window.open(video.url, '_blank');
                }
                else {
                    this.iframeUrl$.next(this.sanitizer.bypassSecurityTrustResourceUrl(video.url));
                }
            }
        });
        // hide sidebar on mobile
        if (this.breakpoints.isMobile$.value) {
            this.toggleSidebar();
        }
    }
    ngOnDestroy() {
        this.store.dispatch(new PlayerOverlayClosed());
        this.plyr && this.plyr.destroy();
    }
    playVideo(video) {
        this.store.dispatch(new PlayVideo(video, video.title));
    }
    toggleSidebar() {
        this.store.dispatch(new ToggleSidebar());
    }
    close() {
        this.overlayRef.close();
    }
    isTabletOrMobile() {
        return this.breakpoints.isMobile$.value ||
            this.breakpoints.isTablet$.value;
    }
    shouldUsePlyr(video) {
        return video.type === 'video' ||
            (video.type === 'embed' && this.embedSupportedByPlyr(video.url));
    }
    loadPlyr(video) {
        if (this.plyr) {
            this.plyr.source = this.getPlyrSource(video);
            return new Promise(resolve => resolve());
        }
        else {
            this.loading$.next(true);
            return Promise.all([
                this.lazyLoader.loadAsset('js/plyr.min.js', { type: 'js' }),
                this.lazyLoader.loadAsset('css/plyr.min.css', { type: 'css' }),
            ]).then(() => {
                this.loading$.next(false);
                this.plyr = new Plyr(this.plyrEl.nativeElement, { autoplay: true });
                this.plyr.source = this.getPlyrSource(video);
            });
        }
    }
    isYoutube(url) {
        return url.includes('youtube.com');
    }
    isVimeo(url) {
        return url.includes('vimeo.com');
    }
    embedSupportedByPlyr(url) {
        return this.isYoutube(url) || this.isVimeo(url);
    }
    getPlyrSource(video) {
        if (video.type === 'embed') {
            return {
                type: 'video',
                poster: video.thumbnail,
                sources: [{
                        src: video.url,
                        provider: this.isYoutube(video.url) ? 'youtube' : 'vimeo',
                    }],
            };
        }
        else {
            const tracks = (video.captions || []).map((caption, i) => {
                return {
                    kind: 'captions',
                    label: caption.name,
                    srclang: caption.language,
                    src: caption.url ? caption.url : this.settings.getBaseUrl() + 'secure/caption/' + caption.id,
                    default: i === 0,
                };
            });
            return {
                type: 'video',
                title: video.name,
                sources: [
                    { src: video.url },
                ],
                poster: video.thumbnail,
                tracks: tracks,
            };
        }
    }
}
tslib_1.__decorate([
    Select(PlayerState.activeVideo),
    tslib_1.__metadata("design:type", Observable)
], PlayerComponent.prototype, "activeVideo$", void 0);
tslib_1.__decorate([
    Select(PlayerState.relatedVideos),
    tslib_1.__metadata("design:type", Observable)
], PlayerComponent.prototype, "relatedVideos$", void 0);
tslib_1.__decorate([
    Select(PlayerState.mediaItem),
    tslib_1.__metadata("design:type", Observable)
], PlayerComponent.prototype, "mediaItem$", void 0);
tslib_1.__decorate([
    Select(PlayerState.sidebarOpen),
    tslib_1.__metadata("design:type", Observable)
], PlayerComponent.prototype, "sidebarOpen$", void 0);
