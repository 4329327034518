import { AppHttpClient } from '../../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class SearchService {
    constructor(http) {
        this.http = http;
    }
    everything(query, queryParams) {
        return this.http.get('search/' + encodeURIComponent(query), queryParams);
    }
}
SearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.ɵɵinject(i1.AppHttpClient)); }, token: SearchService, providedIn: "root" });
