import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { ListsService } from '../../lists.service';
import { MESSAGES } from '../../../../toast-messages';
import { finalize, tap } from 'rxjs/operators';
import { AddRating, AddToWatchlist, ClearUserLists, LoadUserLists, RemoveFromWatchlist, RemoveRating, SetRatings, SetWatchlist } from './user-lists-state-actions';
import { CurrentUser } from '@common/auth/current-user';
import { Toast } from '@common/core/ui/toast.service';
let UserListsState = class UserListsState {
    constructor(lists, currentUser, toast) {
        this.lists = lists;
        this.currentUser = currentUser;
        this.toast = toast;
    }
    static loading(state) {
        return state.loading;
    }
    static watchlist(state) {
        return state.watchlist;
    }
    static lists(state) {
        return state.lists;
    }
    static ratings(state) {
        return state.ratings;
    }
    addToWatchlist(ctx, action) {
        ctx.patchState({ loading: true });
        const listId = ctx.getState().watchlist.id;
        return this.lists.addItem(listId, action.item).pipe(tap(() => {
            this.toast.open(MESSAGES.WATCHLIST_ADD_SUCCESS);
            const watchlist = ctx.getState().watchlist;
            watchlist.items.push({ id: action.item.id, type: action.item.type });
            ctx.patchState({ watchlist: Object.assign({}, watchlist) });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    removeFromWatchlist(ctx, action) {
        ctx.patchState({ loading: true });
        const listId = ctx.getState().watchlist.id;
        return this.lists.removeItem(listId, action.item).pipe(tap(() => {
            this.toast.open(MESSAGES.WATCHLIST_REMOVE_SUCCESS);
            const watchlist = ctx.getState().watchlist;
            const i = watchlist.items.findIndex(item => item.id === action.item.id && item.type === action.item.type);
            watchlist.items.splice(i, 1);
            ctx.patchState({ watchlist: Object.assign({}, watchlist) });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    setWatchlist(ctx, action) {
        ctx.patchState({ watchlist: action.watchlist });
    }
    setRatings(ctx, action) {
        ctx.patchState({ ratings: action.ratings || [] });
    }
    addRating(ctx, action) {
        const oldRatings = ctx.getState().ratings.slice();
        const index = oldRatings.findIndex(r => r.id === action.rating.id);
        if (index > -1) {
            oldRatings[index] = action.rating;
        }
        else {
            oldRatings.push(action.rating);
        }
        ctx.patchState({ ratings: oldRatings });
    }
    removeRating(ctx, action) {
        const newRatings = ctx.getState().ratings.filter(rating => {
            return rating.id !== action.rating.id;
        });
        ctx.patchState({ ratings: newRatings });
    }
    loadUserLists(ctx) {
        if (ctx.getState().lists.length)
            return;
        ctx.patchState({ loading: true });
        const params = { userId: this.currentUser.get('id') };
        return this.lists.getAll(params).pipe(tap(response => {
            ctx.patchState({ lists: response.pagination.data });
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    clearUserLists(ctx) {
        ctx.patchState({ lists: [] });
    }
};
tslib_1.__decorate([
    Action(AddToWatchlist),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddToWatchlist]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState.prototype, "addToWatchlist", null);
tslib_1.__decorate([
    Action(RemoveFromWatchlist),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, RemoveFromWatchlist]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState.prototype, "removeFromWatchlist", null);
tslib_1.__decorate([
    Action(SetWatchlist),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetWatchlist]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState.prototype, "setWatchlist", null);
tslib_1.__decorate([
    Action(SetRatings),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetRatings]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState.prototype, "setRatings", null);
tslib_1.__decorate([
    Action(AddRating),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddRating]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState.prototype, "addRating", null);
tslib_1.__decorate([
    Action(RemoveRating),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, RemoveRating]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState.prototype, "removeRating", null);
tslib_1.__decorate([
    Action(LoadUserLists),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState.prototype, "loadUserLists", null);
tslib_1.__decorate([
    Action(ClearUserLists),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState.prototype, "clearUserLists", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState, "loading", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState, "watchlist", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState, "lists", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], UserListsState, "ratings", null);
UserListsState = tslib_1.__decorate([
    State({
        name: 'userLists',
        defaults: {
            loading: false,
            ratings: [],
            lists: [],
        }
    }),
    tslib_1.__metadata("design:paramtypes", [ListsService,
        CurrentUser,
        Toast])
], UserListsState);
export { UserListsState };
