import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { AppState } from './state/app-state';
import { Observable } from 'rxjs';
import { CustomHomepage } from '@common/core/pages/shared/custom-homepage.service';
import { Settings } from '@common/core/config/settings.service';
import { AppHttpClient } from '@common/core/http/app-http-client.service';
import { MetaTagsService } from '@common/core/meta/meta-tags.service';
import cssVars from 'css-vars-ponyfill';
export class AppComponent {
    constructor(customHomepage, settings, httpClient, router, meta) {
        this.customHomepage = customHomepage;
        this.settings = settings;
        this.httpClient = httpClient;
        this.router = router;
        this.meta = meta;
    }
    ngOnInit() {
        this.customHomepage.select([]);
        this.settings.setHttpClient(this.httpClient);
        this.meta.init();
        // google analytics
        if (this.settings.get('analytics.tracking_code')) {
            this.triggerAnalyticsPageView();
        }
        this.loadCssVariablesPolyfill();
    }
    triggerAnalyticsPageView() {
        this.router.events
            .pipe(filter(e => e instanceof NavigationEnd))
            .subscribe((event) => {
            if (!window['ga'])
                return;
            window['ga']('set', 'page', event.urlAfterRedirects);
            window['ga']('send', 'pageview');
        });
    }
    loadCssVariablesPolyfill() {
        const isNativeSupport = typeof window !== 'undefined' &&
            window['CSS'] &&
            window['CSS'].supports &&
            window['CSS'].supports('(--a: 0)');
        if (!isNativeSupport) {
            cssVars();
        }
    }
}
tslib_1.__decorate([
    Select(AppState.loading),
    tslib_1.__metadata("design:type", Observable)
], AppComponent.prototype, "loading$", void 0);
