import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class ReviewService {
    constructor(http) {
        this.http = http;
    }
    getAll(params) {
        return this.http.get('reviews', params);
    }
    create(params) {
        return this.http.post('reviews', params);
    }
    update(id, payload) {
        return this.http.put('reviews/' + id, payload);
    }
    delete(ids) {
        return this.http.delete('reviews', { ids });
    }
}
ReviewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReviewService_Factory() { return new ReviewService(i0.ɵɵinject(i1.AppHttpClient)); }, token: ReviewService, providedIn: "root" });
