/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../landing/landing.component.ngfactory";
import * as i2 from "../../landing/landing.component";
import * as i3 from "../../../../common/core/config/settings.service";
import * as i4 from "../homepage.component.ngfactory";
import * as i5 from "../homepage.component";
import * as i6 from "@angular/common";
import * as i7 from "./homepage-host.component";
import * as i8 from "../../../../common/auth/current-user";
import * as i9 from "@ngxs/store";
var styles_HomepageHostComponent = [];
var RenderType_HomepageHostComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HomepageHostComponent, data: {} });
export { RenderType_HomepageHostComponent as RenderType_HomepageHostComponent };
function View_HomepageHostComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "landing", [], null, null, null, i1.View_LandingComponent_0, i1.RenderType_LandingComponent)), i0.ɵdid(1, 114688, null, 0, i2.LandingComponent, [i3.Settings], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_HomepageHostComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "homepage", [], null, null, null, i4.View_HomepageComponent_0, i4.RenderType_HomepageComponent)), i0.ɵdid(1, 49152, null, 0, i5.HomepageComponent, [i3.Settings], null, null)], null, null); }
export function View_HomepageHostComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomepageHostComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["homepage", 2]], null, 0, null, View_HomepageHostComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.settings.get("billing.force_subscription") && !_co.currentUser.isLoggedIn()); var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_HomepageHostComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "homepage-host", [], null, null, null, View_HomepageHostComponent_0, RenderType_HomepageHostComponent)), i0.ɵdid(1, 114688, null, 0, i7.HomepageHostComponent, [i3.Settings, i8.CurrentUser, i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomepageHostComponentNgFactory = i0.ɵccf("homepage-host", i7.HomepageHostComponent, View_HomepageHostComponent_Host_0, {}, {}, []);
export { HomepageHostComponentNgFactory as HomepageHostComponentNgFactory };
