import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { LoadRelatedTitles } from '../../state/title-actions';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
export class RelatedTitlesPanelComponent {
    constructor(store, route) {
        this.store = store;
        this.route = route;
    }
    // need at least 4 related titles to display properly
    get noRelatedTitles() {
        const length = this.store.selectSnapshot(TitleState.relatedTitles).length;
        return length < 4;
    }
    ngOnInit() {
        this.route.params.subscribe(() => {
            this.store.dispatch(new LoadRelatedTitles());
        });
    }
}
tslib_1.__decorate([
    Select(TitleState.relatedTitles),
    tslib_1.__metadata("design:type", Observable)
], RelatedTitlesPanelComponent.prototype, "related$", void 0);
