import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
const BASE_URI = 'caption';
export class CaptionService {
    constructor(http) {
        this.http = http;
    }
    all() {
        return this.http.get(BASE_URI);
    }
    create(params) {
        return this.http.post(BASE_URI, params);
    }
    update(id, params) {
        return this.http.put(`${BASE_URI}/${id}`, params);
    }
    delete(ids) {
        return this.http.delete(`${BASE_URI}/${ids}`);
    }
    reorder(videoId, captionIds) {
        return this.http.post(`${BASE_URI}/${videoId}/order`, { ids: captionIds });
    }
}
CaptionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CaptionService_Factory() { return new CaptionService(i0.ɵɵinject(i1.AppHttpClient)); }, token: CaptionService, providedIn: "root" });
