import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LoadRelatedVideos, PlayerOverlayClosed, PlayerOverlayOpened, PlayVideo, ToggleSidebar } from './player-state-actions';
import { TitlesService } from '../../titles/titles.service';
import { MEDIA_TYPE } from '../../media-type';
import { Settings } from '@common/core/config/settings.service';
let PlayerState = class PlayerState {
    constructor(titles, settings) {
        this.titles = titles;
        this.settings = settings;
    }
    static isOpen(state) {
        return state.isOpen;
    }
    static activeVideo(state) {
        return state.activeVideo;
    }
    static relatedVideos(state) {
        return state.relatedVideos;
    }
    static mediaItem(state) {
        return state.mediaItem;
    }
    static sidebarOpen(state) {
        return state.sidebarOpen;
    }
    playVideo(ctx, action) {
        const state = ctx.getState();
        // already have this video and title loaded
        if (state.activeVideo && state.activeVideo.id === action.video.id) {
            return;
        }
        // if playing different videos for same title, there's no need to replace media item
        const newState = { activeVideo: action.video };
        if (action.mediaItem) {
            newState.mediaItem = action.mediaItem;
        }
        ctx.patchState(newState);
        const videoType = this.settings.get('streaming.related_videos_type');
        // related videos panel will be hidden
        if (videoType === 'hide')
            return;
        // load related videos from other titles or other episodes from same title (if it's a series)
        const loadRelatedEpisodes = action.video.episode && this.settings.get('player.show_next_episodes');
        if (videoType === 'other_titles' || loadRelatedEpisodes) {
            ctx.dispatch(new LoadRelatedVideos());
            // show other videos from this title
        }
        else {
            const contentType = this.settings.get('streaming.video_panel_content'), relatedVideos = action.mediaItem.videos.filter(video => {
                return contentType === 'all' ||
                    (contentType === 'short' && video.category !== 'full') ||
                    video.category === contentType;
            });
            ctx.patchState({ relatedVideos });
        }
    }
    loadRelatedVideos(ctx) {
        const mediaItem = ctx.getState().mediaItem, video = ctx.getState().activeVideo;
        const params = {
            episode: video.episode,
            season: video.season,
            videoId: video.id,
        };
        mediaItem.type === MEDIA_TYPE.EPISODE ?
            params.titleId = mediaItem.title_id :
            params.titleId = mediaItem.id;
        return this.titles.getRelatedVideos(params).pipe(tap(response => {
            ctx.patchState({ relatedVideos: response.videos });
        }));
    }
    playerOverlayClosed(ctx) {
        return ctx.patchState({ isOpen: false });
    }
    playerOverlayOpened(ctx) {
        return ctx.patchState({ isOpen: true });
    }
    toggleSidebar(ctx, action) {
        const state = action.open === null ? !ctx.getState().sidebarOpen : action.open;
        return ctx.patchState({ sidebarOpen: state });
    }
};
tslib_1.__decorate([
    Action(PlayVideo),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, PlayVideo]),
    tslib_1.__metadata("design:returntype", void 0)
], PlayerState.prototype, "playVideo", null);
tslib_1.__decorate([
    Action(LoadRelatedVideos),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PlayerState.prototype, "loadRelatedVideos", null);
tslib_1.__decorate([
    Action(PlayerOverlayClosed),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PlayerState.prototype, "playerOverlayClosed", null);
tslib_1.__decorate([
    Action(PlayerOverlayOpened),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PlayerState.prototype, "playerOverlayOpened", null);
tslib_1.__decorate([
    Action(ToggleSidebar),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ToggleSidebar]),
    tslib_1.__metadata("design:returntype", void 0)
], PlayerState.prototype, "toggleSidebar", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PlayerState, "isOpen", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PlayerState, "activeVideo", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PlayerState, "relatedVideos", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PlayerState, "mediaItem", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PlayerState, "sidebarOpen", null);
PlayerState = tslib_1.__decorate([
    State({
        name: 'player',
        defaults: {
            relatedVideos: [],
            isOpen: false,
            sidebarOpen: true,
        }
    }),
    tslib_1.__metadata("design:paramtypes", [TitlesService,
        Settings])
], PlayerState);
export { PlayerState };
