import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SearchEverything } from '../state/search-state-actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/router";
export class SearchResultsResolverService {
    constructor(store, router) {
        this.store = store;
        this.router = router;
    }
    resolve(route) {
        if (route.queryParams.query) {
            return this.store.dispatch(new SearchEverything(route.queryParams.query, null, 20));
        }
        else {
            return this.router.navigate(['/']);
        }
    }
}
SearchResultsResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchResultsResolverService_Factory() { return new SearchResultsResolverService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Router)); }, token: SearchResultsResolverService, providedIn: "root" });
