import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from '@common/core/services/local-storage.service';
import { Settings } from '@common/core/config/settings.service';
import { slugifyString } from '@common/core/utils/slugify-string';
import * as i0 from "@angular/core";
import * as i1 from "./services/local-storage.service";
import * as i2 from "./config/settings.service";
export class ThemeService {
    constructor(localStorage, settings) {
        this.localStorage = localStorage;
        this.settings = settings;
        this.selectedTheme$ = new BehaviorSubject(null);
        this.rootEl = document.documentElement;
    }
    registerThemes(themes) {
        this.registeredThemes = themes;
        if (!this.selectedTheme$.value) {
            this.select(this.getPreferredTheme());
        }
    }
    select(name) {
        const theme = this.registeredThemes[name];
        this.selectedTheme$.next(theme);
        this.setPreferredTheme(name);
        if (theme.is_dark) {
            this.rootEl.classList.remove('be-light-mode');
            this.rootEl.classList.add('be-dark-mode');
        }
        else {
            this.rootEl.classList.remove('be-dark-mode');
            this.rootEl.classList.add('be-light-mode');
        }
        Object.entries(theme.colors).forEach(([key, value]) => {
            this.rootEl.style.setProperty(key, value);
        });
    }
    toggle() {
        if (this.selectedTheme$.value.is_dark) {
            this.select('light');
        }
        else {
            this.select('dark');
        }
    }
    getPreferredTheme() {
        const defaultMode = this.settings.get('themes.default_mode', 'light');
        if (this.settings.get('themes.user_change')) {
            return this.localStorage.get(this.storageKey()) || defaultMode;
        }
        else {
            return defaultMode;
        }
    }
    setPreferredTheme(name) {
        this.localStorage.set(this.storageKey(), name);
    }
    storageKey() {
        return slugifyString(this.settings.get('branding.site_name')) + '.theme';
    }
}
ThemeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(i0.ɵɵinject(i1.LocalStorage), i0.ɵɵinject(i2.Settings)); }, token: ThemeService, providedIn: "root" });
