import * as tslib_1 from "tslib";
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { MEDIA_TYPE } from '../../../media-type';
import { TitleUrlsService } from '../../title-urls.service';
import { shareLinkSocially } from '../../../../../common/core/utils/share-link-socially';
import { Settings } from '../../../../../common/core/config/settings.service';
import { shareViaEmail } from '../../../../../common/core/utils/share-via-email';
import { copyToClipboard } from '../../../../../common/core/utils/copy-link-to-clipboard';
import { MESSAGES } from '../../../../toast-messages';
import { Translations } from '../../../../../common/core/translations/translations.service';
import { Toast } from '../../../../../common/core/ui/toast.service';
export class TitlePrimaryDetailsPanelComponent {
    constructor(urls, settings, store, i18n, toast) {
        this.urls = urls;
        this.settings = settings;
        this.store = store;
        this.i18n = i18n;
        this.toast = toast;
    }
    isEpisode() {
        return this.item.type === MEDIA_TYPE.EPISODE;
    }
    isSeries() {
        return this.item.type === MEDIA_TYPE.TITLE && this.item.is_series;
    }
    isMovie() {
        return this.item.type === MEDIA_TYPE.TITLE && !this.item.is_series;
    }
    shareUsing(type) {
        const title = this.store.selectSnapshot(TitleState.title);
        const link = this.settings.getBaseUrl(true) + 'titles/' + title.id;
        if (type === 'mail') {
            const siteName = this.settings.get('branding.site_name');
            const subject = this.i18n.t('Check out this link on ') + siteName;
            const body = `${title.name} - ${siteName} - ${link}`;
            shareViaEmail(subject, body);
        }
        else if (type === 'copy') {
            if (copyToClipboard(link)) {
                this.toast.open(MESSAGES.COPY_TO_CLIPBOARD_SUCCESS);
            }
        }
        else {
            shareLinkSocially(type, link, title.name);
        }
    }
    isReleased() {
        return new Date(this.item.release_date) < new Date();
    }
}
tslib_1.__decorate([
    Select(TitleState.title),
    tslib_1.__metadata("design:type", Observable)
], TitlePrimaryDetailsPanelComponent.prototype, "title$", void 0);
