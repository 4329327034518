import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SearchState } from '../state/search-state';
import { Observable } from 'rxjs';
import { ToggleGlobalLoader } from '../../../state/app-state-actions';
import { Reset } from '../state/search-state-actions';
import { ActivatedRoute } from '@angular/router';
export class SearchPageComponent {
    constructor(store, route) {
        this.store = store;
        this.route = route;
    }
    ngOnInit() {
        this.route.queryParams.subscribe(() => {
            // TODO: remove "setTimeout"
            setTimeout(() => {
                this.store.dispatch(new ToggleGlobalLoader(false));
            });
        });
    }
    ngOnDestroy() {
        this.store.dispatch(new Reset());
    }
}
tslib_1.__decorate([
    Select(SearchState.query),
    tslib_1.__metadata("design:type", Observable)
], SearchPageComponent.prototype, "query$", void 0);
tslib_1.__decorate([
    Select(SearchState.movies),
    tslib_1.__metadata("design:type", Observable)
], SearchPageComponent.prototype, "movies$", void 0);
tslib_1.__decorate([
    Select(SearchState.series),
    tslib_1.__metadata("design:type", Observable)
], SearchPageComponent.prototype, "series$", void 0);
tslib_1.__decorate([
    Select(SearchState.people),
    tslib_1.__metadata("design:type", Observable)
], SearchPageComponent.prototype, "people$", void 0);
