import * as tslib_1 from "tslib";
import { Action, Selector, State, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ToggleGlobalLoader } from '../../../state/app-state-actions';
import { LoadPerson, SetPerson } from './person-state-actions';
import { PeopleService } from '../people.service';
import { Router } from '@angular/router';
let PersonState = class PersonState {
    constructor(store, people, router) {
        this.store = store;
        this.people = people;
        this.router = router;
    }
    static person(state) {
        return state.person;
    }
    static credits(state) {
        return state.credits;
    }
    static creditsCount(state) {
        return Object.keys(state.credits).map(department => {
            return state.credits[department].length;
        }).reduce((a, b) => a + b, 0);
    }
    static knownFor(state) {
        return state.knownFor;
    }
    static backdrop(state) {
        const titleWithBackdrop = state.knownFor.find(title => !!title.backdrop);
        return titleWithBackdrop ? titleWithBackdrop.backdrop : null;
    }
    loadPerson(ctx, action) {
        const state = ctx.getState();
        if (state.person && state.person.id === action.personId)
            return;
        return this.people.get(action.personId).pipe(tap(response => {
            this.store.dispatch(new SetPerson(response));
        }));
    }
    setPerson(ctx, action) {
        ctx.patchState({
            person: action.response.person,
            credits: action.response.credits,
            knownFor: action.response.knownFor,
            metaTags: action.response.seo,
        });
        this.store.dispatch(new ToggleGlobalLoader(false));
    }
};
tslib_1.__decorate([
    Action(LoadPerson),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, LoadPerson]),
    tslib_1.__metadata("design:returntype", void 0)
], PersonState.prototype, "loadPerson", null);
tslib_1.__decorate([
    Action(SetPerson),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetPerson]),
    tslib_1.__metadata("design:returntype", void 0)
], PersonState.prototype, "setPerson", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PersonState, "person", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PersonState, "credits", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Number)
], PersonState, "creditsCount", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PersonState, "knownFor", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], PersonState, "backdrop", null);
PersonState = tslib_1.__decorate([
    State({
        name: 'person',
        defaults: {
            knownFor: [],
            metaTags: [],
        }
    }),
    tslib_1.__metadata("design:paramtypes", [Store,
        PeopleService,
        Router])
], PersonState);
export { PersonState };
