import { Store } from '@ngxs/store';
import { EMPTY, of } from 'rxjs';
import { LoadLists } from './state/homepage-state.actions';
import { ToggleGlobalLoader } from '../../state/app-state-actions';
import { catchError, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
export class HomepageListsResolverService {
    constructor(store) {
        this.store = store;
    }
    resolve(route) {
        return this.store.dispatch([
            new LoadLists(),
            new ToggleGlobalLoader(true),
        ]).pipe(catchError(() => {
            return EMPTY;
        }), mergeMap((states) => {
            return of({ seo: states[0].homepage.metaTags });
        }));
    }
}
HomepageListsResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HomepageListsResolverService_Factory() { return new HomepageListsResolverService(i0.ɵɵinject(i1.Store)); }, token: HomepageListsResolverService, providedIn: "root" });
