/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./title-videos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "../../../../common/core/translations/translate.directive";
import * as i4 from "../../../../common/core/translations/translations.service";
import * as i5 from "../../../../common/core/config/settings.service";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/router";
import * as i10 from "@angular/common";
import * as i11 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i12 from "@angular/material/icon";
import * as i13 from "./title-video-table/title-video-table.component.ngfactory";
import * as i14 from "./title-video-table/title-video-table.component";
import * as i15 from "@ngxs/store";
import * as i16 from "./title-video-carousel/title-video-carousel.component.ngfactory";
import * as i17 from "./title-video-carousel/title-video-carousel.component";
import * as i18 from "../../../../common/core/translations/translate.pipe";
import * as i19 from "./title-videos.component";
import * as i20 from "../../../../common/core/ui/dialogs/modal.service";
import * as i21 from "../../../../common/auth/current-user";
var styles_TitleVideosComponent = [i0.styles];
var RenderType_TitleVideosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TitleVideosComponent, data: {} });
export { RenderType_TitleVideosComponent as RenderType_TitleVideosComponent };
function View_TitleVideosComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "add-video-btn"], ["color", "accent"], ["mat-flat-button", ""], ["trans", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddVideoModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), i1.ɵdid(2, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Add Video"]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TitleVideosComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["class", "season-list-item"], ["color", "gray"], ["mat-flat-button", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatAnchor_0, i2.RenderType_MatAnchor)), i1.ɵdid(1, 180224, null, 0, i6.MatAnchor, [i7.FocusMonitor, i1.ElementRef, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(2, 671744, null, 0, i9.RouterLinkWithHref, [i9.Router, i9.ActivatedRoute, i10.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 2), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "play-arrow"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null], [2, i12.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 1)], function (_ck, _v) { var currVal_6 = "gray"; _ck(_v, 1, 0, currVal_6); var currVal_7 = _ck(_v, 3, 0, "season", _v.context.$implicit.number); _ck(_v, 2, 0, currVal_7); var currVal_10 = "play-arrow"; _ck(_v, 5, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled ? (0 - 1) : (i1.ɵnov(_v, 1).tabIndex || 0)); var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); var currVal_2 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_3 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_4 = i1.ɵnov(_v, 2).target; var currVal_5 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = i1.ɵnov(_v, 5).inline; var currVal_9 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_8, currVal_9); var currVal_11 = ((i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent, 0), "Season:")) + " ") + _v.context.$implicit.number); _ck(_v, 7, 0, currVal_11); }); }
function View_TitleVideosComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "season-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TitleVideosComponent_3)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.seasons$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TitleVideosComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "title-video-table", [], null, null, null, i13.View_TitleVideoTableComponent_0, i13.RenderType_TitleVideoTableComponent)), i1.ɵdid(1, 49152, null, 0, i14.TitleVideoTableComponent, [i15.Store], null, null)], null, null); }
function View_TitleVideosComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "title-video-carousel", [], null, null, null, i16.View_TitleVideoCarouselComponent_0, i16.RenderType_TitleVideoCarouselComponent)), i1.ɵdid(1, 49152, null, 0, i17.TitleVideoCarouselComponent, [i15.Store], null, null)], null, null); }
export function View_TitleVideosComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i18.TransPipe, [i4.Translations]), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "text"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(3, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Videos"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleVideosComponent_1)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TitleVideosComponent_2)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleVideosComponent_4)), i1.ɵdid(11, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["carousel", 2]], null, 0, null, View_TitleVideosComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentUser.hasPermission("videos.create"); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.isSeries(i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.title$))) && (_co.settings.get("streaming.video_panel_content") === "full")); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.settings.get("titles.video_panel_mode") === "table"); var currVal_3 = i1.ɵnov(_v, 12); _ck(_v, 11, 0, currVal_2, currVal_3); }, null); }
export function View_TitleVideosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "title-videos", [], null, null, null, View_TitleVideosComponent_0, RenderType_TitleVideosComponent)), i1.ɵdid(1, 49152, null, 0, i19.TitleVideosComponent, [i20.Modal, i15.Store, i21.CurrentUser, i5.Settings], null, null)], null, null); }
var TitleVideosComponentNgFactory = i1.ɵccf("title-videos", i19.TitleVideosComponent, View_TitleVideosComponent_Host_0, {}, {}, []);
export { TitleVideosComponentNgFactory as TitleVideosComponentNgFactory };
