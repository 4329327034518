import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ToggleGlobalLoader } from '../../../../state/app-state-actions';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { TitleUrlsService } from '../../title-urls.service';
import { map } from 'rxjs/operators';
export class FullCreditsPageComponent {
    constructor(store, urls) {
        this.store = store;
        this.urls = urls;
        this.groupedCredits$ = this.store.select(TitleState.titleOrEpisodeCredits)
            .pipe(map(this.groupCredits));
    }
    ngOnInit() {
        setTimeout(() => this.store.dispatch(new ToggleGlobalLoader(false)));
    }
    trackByFn(title) {
        return title.id;
    }
    groupCredits(credits) {
        return credits.reduce((h, a) => {
            return Object.assign(h, { [a.pivot.department]: (h[a.pivot.department] || []).concat(a) });
        }, {});
    }
}
tslib_1.__decorate([
    Select(TitleState.title),
    tslib_1.__metadata("design:type", Observable)
], FullCreditsPageComponent.prototype, "title$", void 0);
tslib_1.__decorate([
    Select(TitleState.backdrop),
    tslib_1.__metadata("design:type", Observable)
], FullCreditsPageComponent.prototype, "backdropImage$", void 0);
