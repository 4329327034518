import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { HomepageState } from '../state/homepage-state';
import { interval, Observable } from 'rxjs';
import { ChangeSlide } from '../state/homepage-state.actions';
import { TitleUrlsService } from '../../titles/title-urls.service';
import { MEDIA_TYPE } from '../../media-type';
import { PlayVideo } from '../../player/state/player-state-actions';
import { BreakpointsService } from '../../../../common/core/ui/breakpoints.service';
import { filter } from 'rxjs/operators';
import { Settings } from '../../../../common/core/config/settings.service';
export class SliderComponent {
    constructor(store, urls, breakpoints, settings, el) {
        this.store = store;
        this.urls = urls;
        this.breakpoints = breakpoints;
        this.settings = settings;
        this.el = el;
        this.suspendAutoSlide = false;
    }
    ngOnInit() {
        this.enableAutoSlide();
    }
    enableAutoSlide() {
        if (!this.settings.get('homepage.autoslide'))
            return;
        this.el.nativeElement.addEventListener('mouseenter', () => {
            this.suspendAutoSlide = true;
        });
        this.el.nativeElement.addEventListener('mouseleave', () => {
            this.suspendAutoSlide = false;
        });
        interval(10000)
            .pipe(filter(() => !this.suspendAutoSlide))
            .subscribe(() => {
            this.changeSlide('next');
        });
    }
    changeSlide(index) {
        const active = this.store.selectSnapshot(HomepageState.activeSlide);
        if (index === 'next') {
            index = active + 1;
        }
        else if (index === 'previous') {
            index = active - 1;
        }
        this.store.dispatch(new ChangeSlide(index));
    }
    filterTitles(items) {
        return items.filter(item => {
            return item.type === MEDIA_TYPE.TITLE;
        });
    }
    playVideo(title) {
        this.store.dispatch(new PlayVideo(title.videos[0], title));
    }
}
tslib_1.__decorate([
    Select(HomepageState.sliderList),
    tslib_1.__metadata("design:type", Observable)
], SliderComponent.prototype, "sliderList$", void 0);
tslib_1.__decorate([
    Select(HomepageState.activeSlide),
    tslib_1.__metadata("design:type", Observable)
], SliderComponent.prototype, "activeSlide$", void 0);
