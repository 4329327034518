import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class TitlesService {
    constructor(http) {
        this.http = http;
    }
    get(titleId, queryParams) {
        return this.http.get('titles/' + titleId, queryParams);
    }
    getAll(queryParams) {
        return this.http.get('titles', queryParams);
    }
    getRelatedVideos(params) {
        return this.http.get('related-videos', params);
    }
    getRelatedTitles(title, params) {
        return this.http.get('titles/' + title.id + '/related', params);
    }
    create(payload) {
        return this.http.post('titles', payload);
    }
    update(id, payload) {
        return this.http.put('titles/' + id, payload);
    }
    delete(ids) {
        return this.http.delete('titles', { ids });
    }
    updateCredit(id, payload) {
        return this.http.put('titles/credits/' + id, { credit: payload });
    }
    addCredit(personId, creditable, pivot) {
        return this.http.post('titles/credits', { personId, creditable, pivot });
    }
    removeCredit(id) {
        return this.http.delete('titles/credits/' + id);
    }
    changeCreditsOrder(payload) {
        return this.http.post('titles/credits/reorder', { ids: payload });
    }
    getEpisode(id) {
        return this.http.get('episodes/' + id);
    }
    deleteEpisode(id) {
        return this.http.delete('episodes/' + id);
    }
    createEpisode(seasonId, payload) {
        return this.http.post('seasons/' + seasonId + '/episodes', payload);
    }
    updateEpisode(id, payload) {
        return this.http.put('episodes/' + id, payload);
    }
    createTag(titleId, params) {
        return this.http.post('titles/' + titleId + '/tags', params);
    }
    detachTag(titleId, tag) {
        return this.http.delete('titles/' + titleId + '/tags/' + tag.type + '/' + tag.id);
    }
    changeVideosOrder(titleId, order) {
        return this.http.post('titles/' + titleId + '/videos/change-order', { ids: order });
    }
    changeImageOrder(titleId, order) {
        return this.http.post('titles/' + titleId + '/images/change-order', { ids: order });
    }
    import(params) {
        return this.http.post('media/import', params);
    }
}
TitlesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TitlesService_Factory() { return new TitlesService(i0.ɵɵinject(i1.AppHttpClient)); }, token: TitlesService, providedIn: "root" });
