import { Router } from '@angular/router';
import { CurrentUser } from '@common/auth/current-user';
import { Settings } from '@common/core/config/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/auth/current-user";
import * as i2 from "@angular/router";
import * as i3 from "../../../common/core/config/settings.service";
export class HomepageHostGuard {
    constructor(currentUser, router, settings) {
        this.currentUser = currentUser;
        this.router = router;
        this.settings = settings;
    }
    canActivate(route, state) {
        if (this.settings.get('billing.force_subscription') && this.currentUser.isLoggedIn() && !this.currentUser.isSubscribed() && !this.currentUser.isAdmin()) {
            this.router.navigate(['/billing/upgrade']);
            return false;
        }
        return true;
    }
}
HomepageHostGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HomepageHostGuard_Factory() { return new HomepageHostGuard(i0.ɵɵinject(i1.CurrentUser), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Settings)); }, token: HomepageHostGuard, providedIn: "root" });
