import { AppHttpClient } from '../../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class ImagesService {
    constructor(http) {
        this.http = http;
    }
    create(file, params) {
        const payload = new FormData();
        payload.append('file', file.native);
        Object.keys(params).forEach(key => {
            payload.append(key, params[key]);
        });
        return this.http.post('images', payload);
    }
    delete(id) {
        return this.http.delete('images', { id });
    }
}
ImagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImagesService_Factory() { return new ImagesService(i0.ɵɵinject(i1.AppHttpClient)); }, token: ImagesService, providedIn: "root" });
