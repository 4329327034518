import * as tslib_1 from "tslib";
import { TitleState } from '../../state/title-state';
import { Select, Store } from '@ngxs/store';
import { Observable, zip } from 'rxjs';
import { TitleUrlsService } from '../../title-urls.service';
export class CurrentNextEpisodePanelComponent {
    constructor(urls, store) {
        this.urls = urls;
        this.store = store;
    }
    get episodes$() {
        return zip(this.currentEpisode$, this.nextEpisode$);
    }
    getEpisodeUrl(episode) {
        const series = this.store.selectSnapshot(TitleState.title);
        return this.urls.episode(series, episode.season_number, episode.episode_number);
    }
}
tslib_1.__decorate([
    Select(TitleState.nextEpisode),
    tslib_1.__metadata("design:type", Observable)
], CurrentNextEpisodePanelComponent.prototype, "nextEpisode$", void 0);
tslib_1.__decorate([
    Select(TitleState.currentEpisode),
    tslib_1.__metadata("design:type", Observable)
], CurrentNextEpisodePanelComponent.prototype, "currentEpisode$", void 0);
