import * as tslib_1 from "tslib";
var ListState_1;
import { Action, Selector, State, Store } from '@ngxs/store';
import { AttachItem, CreateOrUpdateList, DeleteList, DetachItem, ReloadList, ReorderList, ResetState, UpdateDetails } from './list-actions';
import { ListsService } from '../lists.service';
import { finalize, tap } from 'rxjs/operators';
import { UserListsState } from '../user-lists/state/user-lists-state';
import { MESSAGES } from '../../../toast-messages';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ClearUserLists } from '../user-lists/state/user-lists-state-actions';
import { Toast } from '@common/core/ui/toast.service';
import { Router } from '@angular/router';
let ListState = ListState_1 = class ListState {
    constructor(lists, store, toast, router) {
        this.lists = lists;
        this.store = store;
        this.toast = toast;
        this.router = router;
    }
    static items(state) {
        return state.items;
    }
    static updating(state) {
        return !!state.list.id;
    }
    static list(state) {
        return state.list;
    }
    static system(state) {
        return state.list.system;
    }
    static public(state) {
        return !state.list.system && state.list.public;
    }
    static loading(state) {
        return state.loading;
    }
    static params(state) {
        return state.params;
    }
    static totalCount(state) {
        return state.pagination.total || 0;
    }
    static currentCount(state) {
        return state.items.length;
    }
    attachItem(ctx, action) {
        const listId = ctx.getState().list.id;
        const newItem = action.item;
        const alreadyContains = ctx.getState().items
            .find(item => item.id === newItem.id && item.type === newItem.type);
        if (alreadyContains)
            return;
        // list is not created yet
        if (!ctx.getState().list.id) {
            return this.addItem(ctx, action.item);
        }
        ctx.patchState({ loading: true });
        return this.lists.addItem(listId, action.item).pipe(tap(() => {
            this.toast.open(MESSAGES.LIST_ITEM_ADD_SUCCESS);
            this.addItem(ctx, action.item);
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    detachItem(ctx, action) {
        const listId = ctx.getState().list.id;
        // list is not created yet
        if (!listId) {
            return this.removeItem(ctx, action.item);
        }
        ctx.patchState({ loading: true });
        return this.lists.removeItem(listId, action.item).pipe(tap(() => {
            this.toast.open(MESSAGES.LIST_ITEM_REMOVE_SUCCESS);
            this.removeItem(ctx, action.item);
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    updateDetails(ctx, action) {
        ctx.patchState({
            list: Object.assign({}, ctx.getState().list, action.details),
        });
    }
    createOrUpdateList(ctx) {
        ctx.patchState({ loading: true });
        const payload = this.getPayload(ctx);
        let request;
        if (ctx.getState().list.id) {
            request = this.lists.update(ctx.getState().list.id, payload);
        }
        else {
            request = this.lists.create(payload);
        }
        return request.pipe(tap(response => {
            const msg = ctx.getState().list.id ? MESSAGES.LIST_UPDATE_SUCCESS : MESSAGES.LIST_CREATE_SUCCESS;
            ctx.patchState({ list: response.list });
            this.store.dispatch(new ClearUserLists());
            this.router.navigate(this.getListRoute());
            this.toast.open(msg);
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    reloadList(ctx, action) {
        if (!action.id)
            return;
        ctx.patchState({ loading: true });
        if (action.params) {
            ctx.patchState({ params: Object.assign({}, ctx.getState().params, action.params) });
        }
        return this.lists.get(action.id, ctx.getState().params).pipe(tap(response => {
            ctx.patchState({
                list: response.list,
                items: response.items.data,
                pagination: Object.assign({}, response.items, { data: [] }),
            });
        }, () => {
            this.router.navigate(['/lists']);
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    reorderList(ctx, action) {
        const items = [...ctx.getState().items];
        moveItemInArray(items, action.currentIndex, action.newIndex);
        ctx.patchState({ items });
        // list is not created yet
        if (!ctx.getState().list.id)
            return;
        const order = {};
        items.forEach((item, index) => {
            order[index] = item.pivot.id;
        });
        ctx.patchState({ loading: true });
        return this.lists.reorder(ctx.getState().list.id, order).pipe(finalize(() => ctx.patchState({ loading: false })));
    }
    deleteList(ctx, action) {
        ctx.patchState({ loading: true });
        return this.lists.delete([action.id]).pipe(tap(() => {
            this.store.dispatch([
                new ClearUserLists(),
                new ResetState()
            ]);
        }), finalize(() => ctx.patchState({ loading: false })));
    }
    resetState(ctx) {
        ctx.patchState({
            loading: false,
            list: {},
            items: [],
            pagination: {},
            params: {
                sortBy: 'pivot.order',
                sortDir: 'asc',
            }
        });
    }
    addItem(ctx, item) {
        ctx.patchState({
            items: [...ctx.getState().items, item]
        });
    }
    removeItem(ctx, item) {
        const newItems = ctx.getState().items.filter(curr => {
            return curr.id !== item.id;
        });
        ctx.patchState({ items: newItems });
    }
    getPayload(ctx) {
        return {
            details: ctx.getState().list,
            items: ctx.getState().items.map(item => {
                return {
                    id: item.id,
                    type: item.type
                };
            })
        };
    }
    getListRoute() {
        const listId = this.store.selectSnapshot(ListState_1.list).id, watchlistId = this.store.selectSnapshot(UserListsState.watchlist).id;
        if (listId === watchlistId) {
            return ['/watchlist'];
        }
        else {
            return ['/lists', listId];
        }
    }
};
tslib_1.__decorate([
    Action(AttachItem),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AttachItem]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState.prototype, "attachItem", null);
tslib_1.__decorate([
    Action(DetachItem),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DetachItem]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState.prototype, "detachItem", null);
tslib_1.__decorate([
    Action(UpdateDetails),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateDetails]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState.prototype, "updateDetails", null);
tslib_1.__decorate([
    Action(CreateOrUpdateList),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState.prototype, "createOrUpdateList", null);
tslib_1.__decorate([
    Action(ReloadList),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ReloadList]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState.prototype, "reloadList", null);
tslib_1.__decorate([
    Action(ReorderList),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ReorderList]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState.prototype, "reorderList", null);
tslib_1.__decorate([
    Action(DeleteList),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DeleteList]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState.prototype, "deleteList", null);
tslib_1.__decorate([
    Action(ResetState),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState.prototype, "resetState", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState, "items", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState, "updating", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState, "list", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState, "system", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState, "public", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState, "loading", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState, "params", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState, "totalCount", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ListState, "currentCount", null);
ListState = ListState_1 = tslib_1.__decorate([
    State({
        name: 'list',
        defaults: {
            loading: false,
            list: {},
            items: [],
            pagination: {},
            params: {
                sortBy: 'pivot.order',
                sortDir: 'asc',
            }
        }
    }),
    tslib_1.__metadata("design:paramtypes", [ListsService,
        Store,
        Toast,
        Router])
], ListState);
export { ListState };
