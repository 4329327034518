/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./season-episode-number.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./season-episode-number.component";
var styles_SeasonEpisodeNumberComponent = [i0.styles];
var RenderType_SeasonEpisodeNumberComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SeasonEpisodeNumberComponent, data: {} });
export { RenderType_SeasonEpisodeNumberComponent as RenderType_SeasonEpisodeNumberComponent };
export function View_SeasonEpisodeNumberComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSeasonNum(); var currVal_1 = _co.getEpisodeNum(); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SeasonEpisodeNumberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "season-episode-number", [], null, null, null, View_SeasonEpisodeNumberComponent_0, RenderType_SeasonEpisodeNumberComponent)), i1.ɵdid(1, 49152, null, 0, i2.SeasonEpisodeNumberComponent, [], null, null)], null, null); }
var SeasonEpisodeNumberComponentNgFactory = i1.ɵccf("season-episode-number", i2.SeasonEpisodeNumberComponent, View_SeasonEpisodeNumberComponent_Host_0, { episode: "episode" }, {}, []);
export { SeasonEpisodeNumberComponentNgFactory as SeasonEpisodeNumberComponentNgFactory };
