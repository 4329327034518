import { Routes } from '@angular/router';
import { HomepageListsResolverService } from './site/homepage/homepage-lists-resolver.service';
import { ContactComponent } from '@common/contact/contact.component';
import { AuthGuard } from '@common/guards/auth-guard.service';
import { HomepageHostComponent } from './site/homepage/homepage-host/homepage-host.component';
import { HomepageHostGuard } from './site/homepage/homepage-host-guard';
const ɵ0 = { name: 'homepage' }, ɵ1 = () => import("./admin/app-admin.module.ngfactory").then(m => m.AppAdminModuleNgFactory), ɵ2 = () => import("./../common/billing/billing.module.ngfactory").then(m => m.BillingModuleNgFactory);
const routes = [
    { path: '', component: HomepageHostComponent, canActivate: [HomepageHostGuard], resolve: { api: HomepageListsResolverService }, data: ɵ0 },
    { path: 'admin', loadChildren: ɵ1, canLoad: [AuthGuard] },
    { path: 'billing', loadChildren: ɵ2 },
    { path: 'contact', component: ContactComponent },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
