/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./confirm-modal.component";
import * as i11 from "../../translations/translations.service";
import * as i12 from "../../config/settings.service";
var styles_ConfirmModalComponent = [i0.styles];
var RenderType_ConfirmModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmModalComponent, data: {} });
export { RenderType_ConfirmModalComponent as RenderType_ConfirmModalComponent };
function View_ConfirmModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.bodyBold; _ck(_v, 1, 0, currVal_0); }); }
export function View_ConfirmModalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "modal-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "close-button no-style"], ["tabindex", "-1"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "mat-dialog-contents mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_1)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 7, "div", [["class", "buttons right mat-dialog-actions mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "button cancel cancel-button"], ["mat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(17, 0, ["", ""])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["class", "button primary submit-button"], ["color", "accent"], ["mat-raised-button", ""], ["type", "submit"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(19, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(20, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_4 = "close"; _ck(_v, 6, 0, currVal_4); var currVal_6 = _co.data.bodyBold; _ck(_v, 12, 0, currVal_6); var currVal_12 = "accent"; _ck(_v, 19, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 6).inline; var currVal_3 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = _co.data.body; _ck(_v, 10, 0, currVal_5); var currVal_7 = (i1.ɵnov(_v, 16).disabled || null); var currVal_8 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_7, currVal_8); var currVal_9 = _co.data.cancel; _ck(_v, 17, 0, currVal_9); var currVal_10 = (i1.ɵnov(_v, 19).disabled || null); var currVal_11 = (i1.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 18, 0, currVal_10, currVal_11); var currVal_13 = _co.data.ok; _ck(_v, 20, 0, currVal_13); }); }
export function View_ConfirmModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "confirm-modal", [], null, null, null, View_ConfirmModalComponent_0, RenderType_ConfirmModalComponent)), i1.ɵdid(1, 114688, null, 0, i10.ConfirmModalComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA, i11.Translations, i12.Settings], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmModalComponentNgFactory = i1.ɵccf("confirm-modal", i10.ConfirmModalComponent, View_ConfirmModalComponent_Host_0, {}, {}, []);
export { ConfirmModalComponentNgFactory as ConfirmModalComponentNgFactory };
