import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Modal } from '../../../../common/core/ui/dialogs/modal.service';
import { CrupdateReviewModalComponent } from '../crupdate-review-modal/crupdate-review-modal.component';
import { Select, Store } from '@ngxs/store';
import { CrupdateReview, DeleteReview, LoadReviews } from '../../titles/state/title-actions';
import { TitleState } from '../../titles/state/title-state';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentUser } from '../../../../common/auth/current-user';
import { ReviewScoreType } from './review-score-type.enum';
import { finalize } from 'rxjs/operators';
import { ReviewService } from '../../shared/review.service';
import { ConfirmModalComponent } from '../../../../common/core/ui/confirm-modal/confirm-modal.component';
import { Toast } from '../../../../common/core/ui/toast.service';
import { MESSAGES } from '../../../toast-messages';
export class ReviewTabComponent {
    constructor(modal, store, currentUser, reviews, toast) {
        this.modal = modal;
        this.store = store;
        this.currentUser = currentUser;
        this.reviews = reviews;
        this.toast = toast;
        this.loading$ = new BehaviorSubject(false);
    }
    ngOnInit() {
        this.loading$.next(true);
        this.store.dispatch(new LoadReviews())
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe();
    }
    openCrupdateReviewModal() {
        const review = (this.store.selectSnapshot(TitleState.reviews) || [])
            .find(curr => curr.user_id === this.currentUser.get('id'));
        const mediaId = this.store.selectSnapshot(TitleState.title).id;
        this.modal.open(CrupdateReviewModalComponent, { review, mediaId }, 'crupdate-review-modal-container').beforeClosed().subscribe(newReview => {
            if (newReview) {
                this.store.dispatch(new CrupdateReview(newReview));
            }
        });
    }
    maybeDeleteReview(review) {
        this.modal.show(ConfirmModalComponent, {
            title: 'Delete Review',
            body: 'Are you sure you want to delete your review?',
            ok: 'Delete'
        }).afterClosed().subscribe(confirmed => {
            if (!confirmed)
                return;
            this.loading$.next(true);
            this.store.dispatch(new DeleteReview(review))
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(() => this.toast.open(MESSAGES.REVIEW_DELETE_SUCCESS));
        });
    }
    getScoreColor(score) {
        if (score < 5) {
            return ReviewScoreType.LOW;
        }
        else if (score < 7) {
            return ReviewScoreType.MEDIUM;
        }
        else {
            return ReviewScoreType.HIGH;
        }
    }
}
tslib_1.__decorate([
    Select(TitleState.reviews),
    tslib_1.__metadata("design:type", Observable)
], ReviewTabComponent.prototype, "reviews$", void 0);
