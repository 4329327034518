import * as tslib_1 from "tslib";
import { Video } from '../../../../models/video';
import { DeleteVideo, UpdateVideo } from '../../../titles/state/title-actions';
import { Select, Store } from '@ngxs/store';
import { CurrentUser } from '@common/auth/current-user';
import { TitleState } from '../../../titles/state/title-state';
import { Observable } from 'rxjs';
import { CrupdateVideoModalComponent } from '../../crupdate-video-modal/crupdate-video-modal.component';
import { Modal } from '@common/core/ui/dialogs/modal.service';
export class EditTitleVideoWidgetComponent {
    constructor(store, currentUser, modal) {
        this.store = store;
        this.currentUser = currentUser;
        this.modal = modal;
    }
    get shouldShow() {
        return !this.canDeleteVideo() && !this.canEditVideo();
    }
    deleteVideo() {
        this.store.dispatch(new DeleteVideo(this.video));
    }
    canDeleteVideo() {
        return this.video.user_id === this.currentUser.get('id') ||
            this.currentUser.hasPermission('videos.delete');
    }
    canEditVideo() {
        return this.video.user_id === this.currentUser.get('id') ||
            this.currentUser.hasPermission('videos.update');
    }
    openEditModal() {
        const episode = this.store.selectSnapshot(TitleState.episode), title = this.store.selectSnapshot(TitleState.title);
        this.modal.open(CrupdateVideoModalComponent, 
        // title should not be changeable when adding video from any specific title pages and
        // season/episode should not be changeable when adding video from specific episode page
        {
            video: this.video,
            title,
            hideTitleSelect: true,
            hideEpisodeSelect: !!episode,
            episode: episode ? episode.episode_number : null,
            season: episode ? episode.season_number : null,
        }).beforeClosed().subscribe(newVideo => {
            if (!newVideo)
                return;
            this.store.dispatch(new UpdateVideo(newVideo));
        });
    }
}
tslib_1.__decorate([
    Select(TitleState.loading),
    tslib_1.__metadata("design:type", Observable)
], EditTitleVideoWidgetComponent.prototype, "loading$", void 0);
