/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-item-header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "../../../../../common/core/ui/material-navbar/material-navbar.component.ngfactory";
import * as i9 from "../../../../../common/core/ui/material-navbar/material-navbar.component";
import * as i10 from "../../../../../common/core/config/settings.service";
import * as i11 from "../../../../../common/auth/current-user";
import * as i12 from "../../../../../common/core/ui/breakpoints.service";
import * as i13 from "../../../search/seach-input/search-input.component.ngfactory";
import * as i14 from "../../../search/seach-input/search-input.component";
import * as i15 from "../../../search/search.service";
import * as i16 from "@angular/router";
import * as i17 from "../../titles.service";
import * as i18 from "../../../people/people.service";
import * as i19 from "@ngxs/store";
import * as i20 from "../../title-urls.service";
import * as i21 from "@angular/common";
import * as i22 from "./media-item-header.component";
var styles_MediaItemHeaderComponent = [i0.styles];
var RenderType_MediaItemHeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MediaItemHeaderComponent, data: {} });
export { RenderType_MediaItemHeaderComponent as RenderType_MediaItemHeaderComponent };
function View_MediaItemHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "play-button play-button-animation"], ["mat-fab", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.playVideo(_v.context.ngIf) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "play-arrow"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_4 = "play-arrow"; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
export function View_MediaItemHeaderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "material-navbar", [["menuPosition", "primary"]], [[2, "transparent", null]], null, null, i8.View_MaterialNavbar_0, i8.RenderType_MaterialNavbar)), i1.ɵdid(1, 114688, null, 0, i9.MaterialNavbar, [i10.Settings, i11.CurrentUser, i1.ElementRef, i12.BreakpointsService], { menuPosition: [0, "menuPosition"], transparent: [1, "transparent"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "search-input", [["class", "nav-searchbar"]], null, null, null, i13.View_SearchInputComponent_0, i13.RenderType_SearchInputComponent)), i1.ɵdid(3, 114688, null, 0, i14.SearchInputComponent, [i15.SearchService, i16.Router, i17.TitlesService, i18.PeopleService, i19.Store, i20.TitleUrlsService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "header-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MediaItemHeaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i21.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i21.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "primary"; var currVal_2 = (_co.transparent || !!_co.backdrop); _ck(_v, 1, 0, currVal_1, currVal_2); _ck(_v, 3, 0); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(((_co.backdrop && _co.settings.get("streaming.show_header_play")) && _co.primaryVideo$))); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).transparent; _ck(_v, 0, 0, currVal_0); }); }
export function View_MediaItemHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "media-item-header", [], [[4, "background-image", null], [2, "no-backdrop", null]], null, null, View_MediaItemHeaderComponent_0, RenderType_MediaItemHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i22.MediaItemHeaderComponent, [i19.Store, i10.Settings], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).backgroundImage; var currVal_1 = i1.ɵnov(_v, 1).noBackdrop; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var MediaItemHeaderComponentNgFactory = i1.ɵccf("media-item-header", i22.MediaItemHeaderComponent, View_MediaItemHeaderComponent_Host_0, { backdrop: "backdrop", transparent: "transparent" }, {}, []);
export { MediaItemHeaderComponentNgFactory as MediaItemHeaderComponentNgFactory };
