import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FormBuilder } from '@angular/forms';
import { LoadFilterOptions, LoadMoreTitles, ReloadTitles } from '../../state/browse/browse-title-actions';
import { BrowseTitleState } from '../../state/browse/browse-title.state';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatSelectionList } from '@angular/material';
import { debounceTime, skip } from 'rxjs/operators';
import { InfiniteScroll } from '@common/core/ui/infinite-scroll/infinite.scroll';
import { BreakpointsService } from '@common/core/ui/breakpoints.service';
import { Settings } from '@common/core/config/settings.service';
import { TITLE_SORT_OPTIONS } from './select-options/title-sort-options';
export class BrowseTitlesComponent extends InfiniteScroll {
    constructor(store, fb, route, breakpoints, settings) {
        super();
        this.store = store;
        this.fb = fb;
        this.route = route;
        this.breakpoints = breakpoints;
        this.settings = settings;
        this.sortOptions = TITLE_SORT_OPTIONS;
        this.form = this.fb.group({
            type: [],
            genre: [],
            released: [],
            score: [],
            country: [],
            language: [],
            runtime: [],
            certification: [],
            order: [],
            onlyStreamable: [],
        });
        this.store.dispatch(new LoadFilterOptions());
    }
    ngOnInit() {
        super.ngOnInit();
        this.route.queryParams.pipe(skip(1)).subscribe(params => {
            if (params.type && params.type !== this.form.value.type) {
                this.form.reset({ type: params.type });
            }
        });
        // reload titles when form is updated
        this.formSub = this.form.valueChanges
            .pipe(debounceTime(50))
            .subscribe(value => {
            this.store.dispatch(new ReloadTitles(this.filterOutNullAndMaxValues(value)));
        });
        const params = BrowseTitleState.queryParamsToFilters(this.route.snapshot.queryParams);
        if (!params.onlyStreamable) {
            params.onlyStreamable = !!this.settings.get('browse.streamable_filter_state', false);
        }
        this.form.patchValue(params);
    }
    filterOutNullAndMaxValues(params) {
        const filtered = {};
        // filters with these values are at maximum range. Rating at
        // (1 to 10) for example so we can remove this filter completely
        const maxValues = ['1,255', '1.0,10.0', '1880,' + this.currentYear()];
        Object.keys(params).forEach(key => {
            const value = params[key], isEmpty = Array.isArray(value) && !value.length;
            if (value && !isEmpty && maxValues.indexOf(value) === -1) {
                filtered[key] = value;
            }
        });
        return filtered;
    }
    ngOnDestroy() {
        this.formSub.unsubscribe();
    }
    clearAllFilters() {
        this.form.reset();
    }
    currentYear() {
        return (new Date()).getFullYear();
    }
    loadMoreItems() {
        this.store.dispatch(new LoadMoreTitles());
    }
    canLoadMore() {
        return this.store.selectSnapshot(BrowseTitleState.canLoadMore);
    }
    isLoading() {
        return this.store.selectSnapshot(BrowseTitleState.loading);
    }
}
tslib_1.__decorate([
    Select(BrowseTitleState.titles),
    tslib_1.__metadata("design:type", Observable)
], BrowseTitlesComponent.prototype, "titles$", void 0);
tslib_1.__decorate([
    Select(BrowseTitleState.doesNotHaveResults),
    tslib_1.__metadata("design:type", Observable)
], BrowseTitlesComponent.prototype, "doesNotHaveResults$", void 0);
tslib_1.__decorate([
    Select(BrowseTitleState.loading),
    tslib_1.__metadata("design:type", Observable)
], BrowseTitlesComponent.prototype, "loading$", void 0);
tslib_1.__decorate([
    Select(BrowseTitleState.anyFilterActive),
    tslib_1.__metadata("design:type", Observable)
], BrowseTitlesComponent.prototype, "anyFilterActive$", void 0);
tslib_1.__decorate([
    Select(BrowseTitleState.countries),
    tslib_1.__metadata("design:type", Observable)
], BrowseTitlesComponent.prototype, "countries$", void 0);
tslib_1.__decorate([
    Select(BrowseTitleState.languages),
    tslib_1.__metadata("design:type", Observable)
], BrowseTitlesComponent.prototype, "languages$", void 0);
tslib_1.__decorate([
    Select(BrowseTitleState.genres),
    tslib_1.__metadata("design:type", Observable)
], BrowseTitlesComponent.prototype, "genres$", void 0);
tslib_1.__decorate([
    Select(BrowseTitleState.certifications),
    tslib_1.__metadata("design:type", Observable)
], BrowseTitlesComponent.prototype, "certifications$", void 0);
