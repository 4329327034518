import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ListState } from '../state/list-state';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ReloadList, ResetState } from '../state/list-actions';
import { UserListsState } from '../user-lists/state/user-lists-state';
import { FormBuilder } from '@angular/forms';
import { LIST_SORT_OPTIONS } from '../types/list-sort-options';
import { CurrentUser } from '../../../../common/auth/current-user';
import { shareLinkSocially } from '../../../../common/core/utils/share-link-socially';
import { Settings } from '../../../../common/core/config/settings.service';
import { shareViaEmail } from '../../../../common/core/utils/share-via-email';
import { copyToClipboard } from '../../../../common/core/utils/copy-link-to-clipboard';
import { Translations } from '../../../../common/core/translations/translations.service';
import { Toast } from '../../../../common/core/ui/toast.service';
import { MESSAGES } from '../../../toast-messages';
export class ListPageComponent {
    constructor(route, store, router, fb, currentUser, settings, i18n, toast) {
        this.route = route;
        this.store = store;
        this.router = router;
        this.fb = fb;
        this.currentUser = currentUser;
        this.settings = settings;
        this.i18n = i18n;
        this.toast = toast;
        this.sortOptions = LIST_SORT_OPTIONS;
        this.listForm = this.fb.group({
            sortBy: ['pivot.order'],
            sortDir: ['asc'],
        });
    }
    ngOnInit() {
        this.route.params.subscribe(() => {
            this.reloadList();
            this.syncFormWithState();
        });
        this.listForm.get('sortBy').valueChanges.subscribe(value => {
            const sortDir = value === 'pivot.order' ? 'asc' : 'desc';
            this.listForm.patchValue({ sortDir }, { emitEvent: false });
        });
        this.listForm.valueChanges.subscribe(() => {
            this.reloadList();
        });
    }
    ngOnDestroy() {
        this.store.dispatch(new ResetState());
    }
    setSortDir(direction) {
        this.listForm.get('sortDir').setValue(direction);
    }
    sortIsActive(direction) {
        return this.listForm.get('sortDir').value === direction;
    }
    canEdit() {
        const list = this.store.selectSnapshot(ListState.list);
        return this.currentUser.get('id') === list.user_id;
    }
    syncFormWithState() {
        const params = this.store.selectSnapshot(ListState.params);
        this.listForm.setValue(params, { emitEvent: false });
    }
    reloadList() {
        const listId = this.route.snapshot.data.watchlist ?
            this.store.selectSnapshot(UserListsState.watchlist).id :
            this.route.snapshot.params.id;
        this.store.dispatch(new ReloadList(listId, this.listForm.value));
    }
    shareListSocially(type) {
        const list = this.store.selectSnapshot(ListState.list);
        const link = this.settings.getBaseUrl(true) + 'lists/' + list.id;
        if (type === 'mail') {
            const siteName = this.settings.get('branding.site_name');
            const subject = this.i18n.t('Check out this link on ') + siteName;
            const body = `${list.name} - ${siteName} - ${link}`;
            shareViaEmail(subject, body);
        }
        else if (type === 'copy') {
            if (copyToClipboard(link)) {
                this.toast.open(MESSAGES.COPY_TO_CLIPBOARD_SUCCESS);
            }
        }
        else {
            shareLinkSocially(type, link, list.name);
        }
    }
}
tslib_1.__decorate([
    Select(ListState.list),
    tslib_1.__metadata("design:type", Observable)
], ListPageComponent.prototype, "list$", void 0);
tslib_1.__decorate([
    Select(ListState.items),
    tslib_1.__metadata("design:type", Observable)
], ListPageComponent.prototype, "items$", void 0);
tslib_1.__decorate([
    Select(ListState.loading),
    tslib_1.__metadata("design:type", Observable)
], ListPageComponent.prototype, "loading$", void 0);
tslib_1.__decorate([
    Select(ListState.totalCount),
    tslib_1.__metadata("design:type", Observable)
], ListPageComponent.prototype, "totalCount$", void 0);
tslib_1.__decorate([
    Select(ListState.currentCount),
    tslib_1.__metadata("design:type", Observable)
], ListPageComponent.prototype, "currentCount$", void 0);
tslib_1.__decorate([
    Select(ListState.public),
    tslib_1.__metadata("design:type", Observable)
], ListPageComponent.prototype, "public$", void 0);
