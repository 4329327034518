import * as tslib_1 from "tslib";
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../../titles/state/title-state';
import { Observable } from 'rxjs';
import { getFaviconFromUrl } from '@common/core/utils/get-favicon-from-url';
import { ReportVideo } from '../../../titles/state/title-actions';
import { PlayVideo } from '../../../player/state/player-state-actions';
export class TitleVideoTableComponent {
    constructor(store) {
        this.store = store;
    }
    playVideo(video) {
        const mediaItem = this.store.selectSnapshot(TitleState.titleOrEpisode);
        if (video.type === 'external') {
            window.open(video.url, '_blank');
        }
        else {
            this.store.dispatch(new PlayVideo(video, mediaItem));
        }
    }
    getFavicon(url) {
        return getFaviconFromUrl(url);
    }
    reportVideo(video) {
        this.store.dispatch(new ReportVideo(video));
    }
}
tslib_1.__decorate([
    Select(TitleState.loading),
    tslib_1.__metadata("design:type", Observable)
], TitleVideoTableComponent.prototype, "loading$", void 0);
tslib_1.__decorate([
    Select(TitleState.videos),
    tslib_1.__metadata("design:type", Observable)
], TitleVideoTableComponent.prototype, "videos$", void 0);
