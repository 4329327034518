/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./streamable-label.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../../common/core/translations/translations.service";
import * as i4 from "../../../../../common/core/config/settings.service";
import * as i5 from "./streamable-label.component";
var styles_StreamableLabelComponent = [i0.styles];
var RenderType_StreamableLabelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StreamableLabelComponent, data: {} });
export { RenderType_StreamableLabelComponent as RenderType_StreamableLabelComponent };
export function View_StreamableLabelComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Streamable"]))], null, null); }
export function View_StreamableLabelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "streamable-label", [], [[2, "hidden", null]], null, null, View_StreamableLabelComponent_0, RenderType_StreamableLabelComponent)), i1.ɵdid(1, 49152, null, 0, i5.StreamableLabelComponent, [i4.Settings], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isHidden; _ck(_v, 0, 0, currVal_0); }); }
var StreamableLabelComponentNgFactory = i1.ɵccf("streamable-label", i5.StreamableLabelComponent, View_StreamableLabelComponent_Host_0, { item: "item" }, {}, []);
export { StreamableLabelComponentNgFactory as StreamableLabelComponentNgFactory };
