import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Settings } from '../config/settings.service';
import { Translations } from '../translations/translations.service';
import { ucFirst } from '../utils/uc-first';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "../config/settings.service";
import * as i4 from "../translations/translations.service";
const TAG_CLASS = 'dst';
export class MetaTagsService {
    constructor(document, router, settings, i18n, route) {
        this.document = document;
        this.router = router;
        this.settings = settings;
        this.i18n = i18n;
        this.route = route;
        this.latestMetaTags$ = new BehaviorSubject(null);
    }
    init() {
        // clear previous route meta tags
        this.router.events
            .pipe(filter(e => e instanceof NavigationStart))
            .subscribe(() => {
            this.latestMetaTags$.next(null);
        });
        this.activeRoute$()
            .subscribe(route => {
            // meta tags were fetched with route resolver
            if (this.latestMetaTags$.value) {
                this.addTags(this.latestMetaTags$.value);
                // route will fetch meta tags via ajax, wait for it
            }
            else if (route.data.willSetSeo) {
                this.latestMetaTags$.pipe(filter(tags => !!tags), take(1)).subscribe(tags => {
                    this.addTags(tags);
                });
                // route does not have specific meta tags, set default ones
            }
            else {
                this.addTags(this.getDefaultTags(route.data));
            }
        });
    }
    addTags(tags) {
        this.removeOldTags();
        const firstChild = this.document.head.firstChild;
        tags.forEach(tag => {
            const node = document.createElement(tag.nodeName);
            node.classList.add(TAG_CLASS);
            Object.keys(tag).forEach(key => {
                if (key === 'nodeName')
                    return;
                if (key === '_text') {
                    node.textContent = typeof tag[key] === 'string' ? tag[key] : JSON.stringify(tag[key]);
                }
                else {
                    node.setAttribute(key, tag[key]);
                }
            });
            this.document.head.insertBefore(node, firstChild);
        });
    }
    removeOldTags() {
        const tags = this.document.head.getElementsByClassName(TAG_CLASS);
        for (let i = 0; i < tags.length; i++) {
            this.document.head.removeChild(tags[i]);
        }
    }
    activeRoute$() {
        return this.router.events
            .pipe(filter(e => e instanceof NavigationEnd), map(() => this.route), map((route) => {
            while (route.firstChild)
                route = route.firstChild;
            return route;
        }), filter((route) => route.outlet === 'primary'), map(route => route.snapshot));
    }
    getDefaultTags(routeData) {
        const title = {
            nodeName: 'title',
            _text: this.settings.get('branding.site_name'),
        };
        const defaultTitle = routeData.title || routeData.name;
        // prepend route name to site name, if available
        if (defaultTitle) {
            const name = this.i18n.t(defaultTitle.replace(/-/g, ' '));
            title._text = name + ' - ' + title._text;
        }
        title._text = ucFirst(title._text);
        return [title];
    }
}
MetaTagsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MetaTagsService_Factory() { return new MetaTagsService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Settings), i0.ɵɵinject(i4.Translations), i0.ɵɵinject(i2.ActivatedRoute)); }, token: MetaTagsService, providedIn: "root" });
