import { UploadValidator } from './upload-validator';
import { FileSizeValidation } from './validations/file-size-validation';
import { AllowedExtensionsValidation } from './validations/allowed-extensions-validation';
import { BlockedExtensionsValidation } from './validations/blocked-extensions-validation';
import { convertToBytes } from '../../core/utils/convertToBytes';
import * as i0 from "@angular/core";
import * as i1 from "../../core/config/settings.service";
import * as i2 from "../../core/ui/toast.service";
import * as i3 from "../../core/translations/translations.service";
export class DefaultUploadValidator extends UploadValidator {
    constructor() {
        super(...arguments);
        this.DEFAULT_MAX_FILE_SIZE_MB = 8;
        this.showToast = true;
    }
    initValidations() {
        this.validations.push(new FileSizeValidation({ maxSize: this.getMaxFileSize() }, this.i18n));
        const allowedExtensions = this.getAllowedExtensions(), blockedExtensions = this.getBlockedExtensions();
        if (allowedExtensions && allowedExtensions.length) {
            this.validations.push(new AllowedExtensionsValidation({ extensions: allowedExtensions }, this.i18n));
        }
        if (blockedExtensions && blockedExtensions.length) {
            this.validations.push(new BlockedExtensionsValidation({ extensions: blockedExtensions }, this.i18n));
        }
    }
    getMaxFileSize() {
        return this.settings.get('uploads.max_size', convertToBytes(this.DEFAULT_MAX_FILE_SIZE_MB, 'MB'));
    }
    getAllowedExtensions() {
        return this.settings.getJson('uploads.allowed_extensions');
    }
    getBlockedExtensions() {
        return this.settings.getJson('uploads.blocked_extensions');
    }
}
DefaultUploadValidator.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DefaultUploadValidator_Factory() { return new DefaultUploadValidator(i0.ɵɵinject(i1.Settings), i0.ɵɵinject(i2.Toast), i0.ɵɵinject(i3.Translations)); }, token: DefaultUploadValidator, providedIn: "root" });
