import { PlayerComponent } from '../player/player.component';
import { PlayVideo } from './player-state-actions';
import { OverlayPanel } from '../../../../common/core/ui/overlay-panel/overlay-panel.service';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { PlayerState } from './player-state';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "../../../../common/core/ui/overlay-panel/overlay-panel.service";
export class PlayerOverlayHandler {
    constructor(store, actions$, overlay) {
        this.store = store;
        this.actions$ = actions$;
        this.overlay = overlay;
        this.actions$.pipe(ofActionSuccessful(PlayVideo))
            .subscribe(() => {
            if (!this.store.selectSnapshot(PlayerState.isOpen)) {
                this.openOverlay();
            }
        });
    }
    openOverlay() {
        this.overlay.open(PlayerComponent, {
            origin: 'global',
            position: 'center',
            panelClass: 'player-overlay-container',
            hasBackdrop: false,
            fullScreen: true,
        });
    }
}
PlayerOverlayHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlayerOverlayHandler_Factory() { return new PlayerOverlayHandler(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.OverlayPanel)); }, token: PlayerOverlayHandler, providedIn: "root" });
